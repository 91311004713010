import React, { useCallback, useState } from 'react';
import nprogress from 'nprogress';
import { ClientCompany } from 'services';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  chakra,
  useToast,
  Button,
  ButtonGroup,
  Box,
  VStack,
  HStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Divider,
} from '@chakra-ui/react';

import Modal from 'components/Modal';
import { Input } from 'components/Forms/Input';

const schema = Yup.object({
  file: Yup.mixed().required('Escolha um arquivo Excel para ser lido pela plataforma.'),
});

export default ({ isOpen, setNewImportItem, onClose = () => {} }) => {
  const toast = useToast();
  const [file, setFile] = useState(null);
  const [imports, setImports] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      nprogress.start();
      const { data } = await ClientCompany.importItem(formData);
      nprogress.done();
      setImports(data);
      localStorage.setItem('LastImportCNPJs', JSON.stringify(data));
      setNewImportItem(true);
      toast({
        title: 'Arquivo importado',
        description: 'Confira os resultados em tela.',
        status: 'success',
        isClosable: true,
      });
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível criar as empresas com todos ou algum(ns) dos CNPJs da listagem.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
    setNewImportItem(true);
  }, [onClose, file]);

  const handleClose = () => {
    onClose();
  };

  console.log('IMPORTS-CNPJS: ', imports);
  return (
    <Modal
      title="Importar CNPJs"
      isOpen={isOpen}
      onClose={handleClose}
      isSubmitting={isSubmitting}
      footer={
        <ButtonGroup spacing="4">
          <Button type="submit" form="formRef" isLoading={isSubmitting}>
            Importar
          </Button>
          <Button colorScheme="grayBlue" variant="ghost" onClick={handleClose}>
            Fechar
          </Button>
        </ButtonGroup>
      }
    >
      <VStack as="form" id="formRef" onSubmit={handleSubmit(handleOnSubmit)} spacing="4">
        <Text>Aqui você poderá fazer upload de uma planilha Excel que contenha uma lista de vários CNPJs.</Text>
        <Text color="gray.400" fontSize="sm" mt="4">
          <chakra.span fontWeight="semibold">Importante:</chakra.span> Os CNPJs devem ficar na primeira coluna da
          planilha, cada um em uma célula.
        </Text>
        <Input
          name="file"
          type="file"
          label="Arquivo"
          onChange={(e) => onChange(e)}
          errors={errors}
          register={register}
        />
      </VStack>
      {(imports?.items || JSON.parse(localStorage.getItem('LastImportCNPJs'))?.items) && (
        <>
          <Divider my="4" />
          <Box bg="grayBlue.100" borderRadius="3" mt="4">
            <Text fontWeight="semibold" color="grayBlue.500">
              {imports?.items ? (
                <>
                  <chakra.span display="block" bg="green.400" p="4" color="white" borderRadius={3}>
                    Resultado AGORA
                  </chakra.span>
                </>
              ) : (
                <>
                  <chakra.span display="block" bg="grayBlue.200" p="4" borderRadius={3}>
                    Registro da Última importação
                  </chakra.span>
                </>
              )}
            </Text>
            <Text my="3" mx="4" color="gray.500" fontSize="sm">
              <chakra.strong>
                {imports?.sent || JSON.parse(localStorage.getItem('LastImportCNPJs'))?.sent || '0'}
              </chakra.strong>{' '}
              CNPJs enviados
            </Text>

            <Accordion allowToggle>
              <AccordionItem>
                <AccordionButton color="green.500">
                  <Box as="span" flex="1" textAlign="left" fontSize="sm">
                    {imports?.success || JSON.parse(localStorage.getItem('LastImportCNPJs'))?.success || '0'}{' '}
                    cadastrados
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} fontSize="sm">
                  {imports?.items
                    ? imports?.items?.map(
                        (itemSuccess, i) =>
                          itemSuccess?.code === 'success' && (
                            <HStack key={i}>
                              <Text>
                                {itemSuccess?.cnpj} - {itemSuccess?.message}
                              </Text>
                            </HStack>
                          )
                      )
                    : JSON.parse(localStorage.getItem('LastImportCNPJs'))?.items
                    ? JSON.parse(localStorage.getItem('LastImportCNPJs'))?.items?.map(
                        (itemSuccess, i) =>
                          itemSuccess?.code === 'success' && (
                            <HStack key={i}>
                              <Text>
                                {itemSuccess?.cnpj} - {itemSuccess?.message}
                              </Text>
                            </HStack>
                          )
                      )
                    : ''}
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton color="red.500">
                  <Box as="span" flex="1" textAlign="left" fontSize="sm">
                    {imports?.error || JSON.parse(localStorage.getItem('LastImportCNPJs'))?.error || '0'} não
                    cadastrados
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4} fontSize="sm">
                  {imports?.items
                    ? imports?.items?.map(
                        (itemError, i) =>
                          itemError?.code === 'error' && (
                            <HStack key={i}>
                              <Text>
                                {itemError?.cnpj} - {itemError?.message}
                              </Text>
                            </HStack>
                          )
                      )
                    : JSON.parse(localStorage.getItem('LastImportCNPJs'))?.items
                    ? JSON.parse(localStorage.getItem('LastImportCNPJs'))?.items?.map(
                        (itemError, i) =>
                          itemError?.code === 'error' && (
                            <HStack key={i}>
                              <Text>
                                {itemError?.cnpj} - {itemError?.message}
                              </Text>
                            </HStack>
                          )
                      )
                    : ''}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </>
      )}
    </Modal>
  );
};
