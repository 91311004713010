export default {
  baseStyle: {
    dialog: {
      borderRadius: '4px 4px 3px 3px ',
    },
    header: {
      bgColor: 'blue.400',
      color: 'white',
      fontWeight: 'medium',
      borderRadius: '3px 3px 0 0',
    },
    body: {
      p: '8',
    },
    footer: {
      bgColor: 'grayBlue.100',
      p: '4',
      borderRadius: '0 0 3px 3px',
    },
  },
  sizes: {
    xxl: {
      dialog: {
        w: '1000px',
        maxWidth: '1000px',
      },
    },
    '2xl': {
      dialog: {
        w: '1400px',
        maxWidth: '1400px',
      },
    },
    '3xl': {
      dialog: {
        w: '1800px',
        maxWidth: '1800px',
      },
    },
    '4xl': {
      dialog: {
        w: '2000px',
        maxWidth: '2000px',
      },
    },
  },
};
