import api from 'services/api';

const timestamp = new Date().getTime();

const index = (args) => {
  return api.get(`/api/v1/admin/groups?${args}`);
};

export default {
  index,
};
