import api from 'services/api';

const index = (args) => {
  return api.get(`/api/v1/client/notification${args}`);
};

const updateItem = (id) => {
  return api.put(`/api/v1/client/notification/${id}`, {});
};

export default {
  index,
  updateItem,
};
