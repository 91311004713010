import React, { useState, useMemo } from 'react';
import nprogress from 'nprogress';
import { AdminLicense } from 'services';
import { useQuery } from '@tanstack/react-query';

import { useDelayedValue } from 'hooks/useDelayedValue';
import { date, daysDistance } from 'utils/format';
import { RiDeleteBin2Fill, RiEdit2Fill } from 'react-icons/ri';

import Breadcrumbs from 'components/Breadcrumbs';
import { Content, Paper } from 'components/Content';
import DefaultTable from 'components/Tables/DefaultTable';

import { useToast, HStack, Button, Badge, Icon, Text, IconButton, Link } from '@chakra-ui/react';

import Create from './create';
import Update from './update';
import Delete from './delete';

import {
  MdSignalCellular0Bar,
  MdSignalCellular1Bar,
  MdSignalCellular2Bar,
  MdSignalCellular3Bar,
  MdSignalCellular4Bar,
} from 'react-icons/md';

import {
  MdSignalWifi0Bar,
  MdSignalWifi1Bar,
  MdSignalWifi2Bar,
  MdSignalWifi3Bar,
  MdSignalWifi4Bar,
} from 'react-icons/md';

const signals = [MdSignalWifi0Bar, MdSignalWifi1Bar, MdSignalWifi2Bar, MdSignalWifi3Bar, MdSignalWifi4Bar];

export default () => {
  const toast = useToast();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(20);
  const [createItem, setCreateItem] = useState(false);
  const [updateItem, setUpdateItem] = useState(0);
  const [deleteItem, setDeleteItem] = useState(0);

  const searchDelayed = useDelayedValue(search, 1000);

  const getLicenses = async (page = 0) => {
    try {
      nprogress.start();
      const { data } = await AdminLicense.index(`&search=${searchDelayed}&page=${page}&limit=${limit}`);
      nprogress.done();
      return data;
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar as licenças.',
        description: error?.response?.data?.message
          ? error?.response?.data?.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const licensesQuery = useQuery({
    queryKey: ['@eConform-getLicenses', page, searchDelayed, limit],
    queryFn: () => getLicenses(page),
    keepPreviousData: true,
  });

  const columns = useMemo(
    () => [
      'ID',
      'Nome',
      'Risco',
      'Complexidade',
      'Esforço',
      'Burocracia',
      'Validade',
      'CNAEs',
      'Criação/Edição',
      'Opções',
    ],
    []
  );

  const licensesData = licensesQuery.data?.items.map((item) => ({
    id: parseInt(item.id),
    name: (
      <Link
        onClick={() => setUpdateItem(item.id)}
        color={item.enabled ? 'blue.400' : 'red.500'}
        fontWeight="semibold"
        alt={item.name}
      >
        {item.name}
        {!item.enabled && <Badge colorScheme="red">Inativo</Badge>}

        {daysDistance(new Date(item.createdAt?.date), 30) && <Badge colorScheme="purple">Novo</Badge>}
      </Link>
    ),
    impact: (
      <Icon
        as={signals[item.impact] || signals[0]}
        boxSize={6}
        color={
          item.impact == '1'
            ? 'green.400'
            : item.impact == '2'
            ? 'yellow.400'
            : item.impact == '3'
            ? 'red.400'
            : 'grayBlue.600'
        }
      />
    ),
    complexity: (
      <Icon
        as={signals[item.complexity] || signals[0]}
        boxSize={6}
        color={
          item.complexity == '1'
            ? 'green.400'
            : item.complexity == '2'
            ? 'yellow.400'
            : item.complexity == '3'
            ? 'red.400'
            : 'grayBlue.600'
        }
      />
    ),
    estimatedEffort: item.estimatedEffort,
    estimatedBureaucratic: item.estimatedBureaucratic,
    estimatedValid: item.estimatedValid,
    totalCnaes: item.totalCnaes,
    createdUpdatedAt: (
      <>
        <Text>{date(item.createdAt ? item.createdAt.date : '')}</Text>
        <Text>{date(item.updatedAt ? item.updatedAt.date : '')}</Text>
      </>
    ),
    options: (
      <HStack spacing="2">
        <IconButton
          icon={<RiEdit2Fill />}
          title="Editar"
          aria-label="Edit"
          variant="ghost"
          fontSize="28"
          color="grayBlue.400"
          cursor="pointer"
          _hover={{
            color: 'blue.400',
            bgColor: 'grayBlue.200',
          }}
          onClick={() => setUpdateItem(item.id)}
        />
        <IconButton
          icon={<RiDeleteBin2Fill />}
          title="Excluir"
          aria-label="Delete"
          variant="ghost"
          fontSize="28"
          color="grayBlue.400"
          cursor="pointer"
          _hover={{
            color: 'red.400',
            bgColor: 'grayBlue.200',
          }}
          onClick={() => setDeleteItem(item.id)}
        />
      </HStack>
    ),
  }));

  return (
    <>
      <Breadcrumbs title="Licenças" pages={[{ page: 'Início', link: '/admin/accounts/business' }]} />

      <Content>
        <Paper
          title="Licenças"
          fluid
          loading={licensesQuery.isLoading}
          fetching={licensesQuery.isFetching || licensesQuery.isRefetching}
          options={<Button onClick={() => setCreateItem(true)}>Adicionar</Button>}
        >
          <DefaultTable
            columns={columns}
            data={licensesData}
            page={page}
            total={licensesQuery.data?.total}
            limit={limit}
            loading={licensesQuery.isLoading}
            fetching={licensesQuery.isFetching || licensesQuery.isRefetching}
            valueSearch={search}
            handleSearch={(e) => {
              if (e.key === 'Enter') {
                setPage(0);
                setSearch(e.target.value);
              } else {
                setPage(0);
                setSearch(e.target.value);
              }
            }}
            handleClearSearch={() => {
              setPage(0);
              setSearch('');
            }}
            fetchData={() => getLicenses(0)}
            handlePerRowsChange={(event) => {
              setLimit(event.target.value);
            }}
            handlePageChange={(item) => {
              setPage(item);
            }}
            pagesInRange={licensesQuery.data?.pages_in_range}
          />
        </Paper>
      </Content>

      <Create
        isOpen={createItem}
        onClose={() => {
          setCreateItem(false);
          licensesQuery.refetch();
        }}
      />
      <Update
        id={updateItem}
        isOpen={!!updateItem}
        onClose={() => {
          setUpdateItem(0);
          licensesQuery.refetch();
        }}
      />
      <Delete
        id={deleteItem}
        isOpen={!!deleteItem}
        onClose={() => {
          setDeleteItem(0);
          licensesQuery.refetch();
        }}
      />
    </>
  );
};
