import React, { useState, useCallback } from 'react';
import nprogress from 'nprogress';
import { useQuery } from '@tanstack/react-query';
import { ClientCompany } from 'services';
import { useNavigate } from 'react-router-dom';

import {
  chakra,
  Box,
  Button,
  ButtonGroup,
  Text,
  Heading,
  Icon,
  Flex,
  FormLabel,
  Image,
  useToast,
  VisuallyHidden,
  HStack,
  VStack,
  Spinner,
  SimpleGrid,
  GridItem,
  Grid,
  Divider,
} from '@chakra-ui/react';
import { date, formatCNPJ } from 'utils/format';
import LogoEConform from 'resources/logo/official/econform-logo-OriginalComFundoTransparente.png';
import BrasaoRFB from 'resources/images/brasaooficialcolorido.png';

export default React.forwardRef(({ companyId, document }, ref) => {
  let navigate = useNavigate();
  const toast = useToast();

  /* COMPANY */
  const getCompany = async () => {
    try {
      const { data } = await ClientCompany.readItem(companyId);
      return data;
    } catch (error) {
      toast({
        title: 'Não foi possível carregar as informações da empresa.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };
  const companyQuery = useQuery({
    queryKey: [`@eConform-getCompanyExport-${companyId}`, companyId],
    queryFn: () => getCompany(),
    keepPreviousData: true,
  });

  /* LICENSES */
  const getLicenses = async () => {
    try {
      nprogress.start();
      const { data } = await ClientCompany.licenseIndex(companyId, `?page=0&limit=99`);
      nprogress.done();
      return data.result[0];
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar as licenças.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };
  const licensesQuery = useQuery({
    queryKey: [`@eConform-getLicensesExport-${companyId}`, companyId],
    queryFn: () => getLicenses(),
    keepPreviousData: true,
  });

  const GridItemCustom = (props) => (
    <GridItem colSpan={props.colSpan} border="1px solid #CBD5E0" padding={2} textAlign="left" {...props}>
      <Box fontSize=".6rem" textTransform="uppercase">
        {props.title}
      </Box>
      <Box fontWeight="semibold" fontSize=".7rem">
        {props.children}
      </Box>
    </GridItem>
  );

  return (
    <Box ref={ref}>
      <HStack fontSize="xs">
        <Text>Emitido por: </Text>
        <img src={LogoEConform} width="70px" height="auto" alt="Logo eConform" />
      </HStack>
      <Box p="2" borderWidth={1} borderStyle="solid" borderColor="grayBlue.700">
        <Grid templateColumns="repeat(12, 1fr)" gap={2} color="grayBlue.700" fontSize="14px" borderRadius={4}>
          <GridItemCustom colSpan={12} title="">
            <Heading as="h3" size="sm" textAlign="center">
              DADOS DA EMPRESA E LICENÇAS
            </Heading>
          </GridItemCustom>

          <GridItemCustom colSpan={4} title="NÚMERO DE INSCRIÇÃO">
            {companyQuery?.data?.registration_code ? formatCNPJ(companyQuery?.data?.registration_code) : 'N/D'} -{' '}
            {companyQuery?.data?.type}
          </GridItemCustom>

          <GridItemCustom colSpan={4} title="NOME EMPRESARIAL">
            {companyQuery?.data?.registration_name == 'undefined' ||
            companyQuery?.data?.registration_name == 'null' ||
            companyQuery?.data?.registration_name == 'Não encontrado' ||
            !companyQuery?.data?.registration_name
              ? 'Não possui'
              : companyQuery?.data?.registration_name}
          </GridItemCustom>

          <GridItemCustom colSpan={4} title="TÍTULO DO ESTABELECIMENTO (NOME FANTASIA)">
            {companyQuery?.data?.registration_fantasy_name == 'undefined' ||
            companyQuery?.data?.registration_fantasy_name == 'null' ||
            companyQuery?.data?.registration_fantasy_name == 'Não encontrado' ||
            !companyQuery?.data?.registration_fantasy_name
              ? 'Não possui'
              : companyQuery?.data?.registration_fantasy_name}
          </GridItemCustom>

          <GridItemCustom colSpan={12} title="Licenças">
            <Grid templateColumns="repeat(12, 1fr)" gap={2} my={4}>
              <GridItem colSpan={8} fontWeight="bold" textTransform="uppercase">
                Licença
              </GridItem>
              <GridItem colSpan={4} fontWeight="bold" textAlign="center" textTransform="uppercase">
                Validade
              </GridItem>
            </Grid>
            {licensesQuery?.data?.items?.map((license) => (
              <Grid templateColumns="repeat(12, 1fr)" gap={2} mb={2}>
                <GridItem colSpan={8}>{license.fiscalLicenseName}</GridItem>
                <GridItem colSpan={4}>
                  {license.validUntil ? (
                    <Text title={date(license.validUntil)} textAlign="center">
                      {/* <Icon as={RiGlobalLine} boxSize={3} color="grayBlue.600" /> */}{' '}
                      {date(license.validUntil, "dd' 'MMM' 'yyyy'")}
                    </Text>
                  ) : license.status !== 'pending' ? (
                    <Text textAlign="center">Indeterminada</Text>
                  ) : (
                    <Text textAlign="center">-</Text>
                  )}
                </GridItem>
              </Grid>
            ))}
          </GridItemCustom>
        </Grid>
      </Box>
    </Box>
  );
});
