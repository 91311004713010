import React, { useState, useMemo } from 'react';
import nprogress from 'nprogress';
import { AdminUser } from 'services';
import { useQuery } from '@tanstack/react-query';

import { useDelayedValue } from 'hooks/useDelayedValue';
import { date, daysDistance } from 'utils/format';
import { RiDeleteBin2Fill, RiEdit2Fill } from 'react-icons/ri';

import Breadcrumbs from 'components/Breadcrumbs';
import { Content, Paper } from 'components/Content';
import DefaultTable from 'components/Tables/DefaultTable';

import { useToast, Button, Badge, Icon, Text, HStack, IconButton, Link, Avatar, Box } from '@chakra-ui/react';

import Create from './create';
import Delete from './delete';
import Update from './update';

export default () => {
  const toast = useToast();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(20);
  const [createItem, setCreateItem] = useState(false);
  const [updateItem, setUpdateItem] = useState(0);
  const [deleteItem, setDeleteItem] = useState(0);

  const searchDelayed = useDelayedValue(search, 1000);

  const getUsers = async (page = 0) => {
    try {
      nprogress.start();
      const { data } = await AdminUser.index(`&search=${searchDelayed}&page=${page}&limit=${limit}`);
      nprogress.done();
      return data;
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar os usuários.',
        description: error?.response?.data?.message
          ? error?.response?.data?.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const usersQuery = useQuery({
    queryKey: ['@eConform-getUsers', page, searchDelayed, limit],
    queryFn: () => getUsers(page),
    keepPreviousData: true,
  });

  const columns = useMemo(() => ['ID', 'Usuário', 'Tipo', 'Criação/Edição', 'Opções'], []);

  const usersData = usersQuery.data?.result[0]?.items?.map((item) => ({
    id: parseInt(item.id),
    nameEmail: (
      <HStack spacing="4">
        <Avatar name={item.name} bgColor="grayBlue.400" color="white" size="sm" />
        <Box>
          <Link
            color={item.enabled ? 'blue.400' : 'red.500'}
            fontWeight="semibold"
            onClick={() => setUpdateItem(item.id)}
          >
            {item.name}
            {!item.enabled && <Badge colorScheme="red">Inativo</Badge>}
            {daysDistance(new Date(item.createdAt?.date), 30) && <Badge colorScheme="purple">Novo</Badge>}
          </Link>
          <Text color="grayBlue.700">{item.email}</Text>
        </Box>
      </HStack>
    ),
    systemRole:
      item.systemRole === 'ECONFORM' ? (
        <Badge colorScheme="green">eConform</Badge>
      ) : item.systemRole === 'PARTNER' ? (
        <Badge colorScheme="purple">Parceiro</Badge>
      ) : item.systemRole === 'BUSINESS' ? (
        <Badge colorScheme="pink">Business</Badge>
      ) : item.systemRole === 'AFFINITY' ? (
        <Badge colorScheme="blue">Affinity</Badge>
      ) : item.systemRole === 'PROVIDER' ? (
        <Badge colorScheme="red">Provider</Badge>
      ) : item.systemRole === 'EDITOR' ? (
        <Badge colorScheme="yellow">Editor</Badge>
      ) : (
        <Badge color="white">{item.systemRole || 'n/d'}</Badge>
      ),
    createdUpdatedAt: (
      <>
        <Text>{date(item.createdAt ? item.createdAt.date : '')}</Text>
        <Text>{date(item.updatedAt ? item.updatedAt.date : '')}</Text>
      </>
    ),
    options: (
      <HStack spacing="2">
        <IconButton
          icon={<RiEdit2Fill />}
          title="Editar"
          aria-label="Edit"
          variant="ghost"
          fontSize="28"
          color="grayBlue.400"
          cursor="pointer"
          _hover={{
            color: 'blue.400',
            bgColor: 'grayBlue.200',
          }}
          onClick={() => setUpdateItem(item.id)}
        />
        <IconButton
          icon={<RiDeleteBin2Fill />}
          title="Excluir"
          aria-label="Delete"
          variant="ghost"
          fontSize="28"
          color="grayBlue.400"
          cursor="pointer"
          _hover={{
            color: 'red.400',
            bgColor: 'grayBlue.200',
          }}
          onClick={() => setDeleteItem(item.id)}
        />
      </HStack>
    ),
  }));

  return (
    <>
      <Breadcrumbs title="Usuários" pages={[{ page: 'Início', link: '/admin/accounts/business' }]} />

      <Content>
        <Paper
          title="Usuários"
          fluid
          loading={usersQuery.isLoading}
          fetching={usersQuery.isFetching || usersQuery.isRefetching}
          options={
            <Button type="button" onClick={() => setCreateItem(true)}>
              Adicionar
            </Button>
          }
        >
          <DefaultTable
            columns={columns}
            data={usersData}
            page={page}
            total={usersQuery.data?.result[0]?.total}
            limit={limit}
            loading={usersQuery.isLoading}
            fetching={usersQuery.isFetching || usersQuery.isRefetching}
            valueSearch={search}
            handleSearch={(e) => {
              if (e.key === 'Enter') {
                setPage(0);
                setSearch(e.target.value);
              } else {
                setPage(0);
                setSearch(e.target.value);
              }
            }}
            handleClearSearch={() => {
              setPage(0);
              setSearch('');
            }}
            fetchData={() => getUsers(0)}
            handlePerRowsChange={(event) => {
              setLimit(event.target.value);
            }}
            handlePageChange={(item) => {
              setPage(item);
            }}
            pagesInRange={usersQuery.data?.result[0]?.pages_in_range}
          />
        </Paper>
      </Content>
      <Create
        isOpen={createItem}
        onClose={() => {
          setCreateItem(false);
          usersQuery.refetch();
        }}
      />
      <Update
        id={updateItem}
        isOpen={!!updateItem}
        onClose={() => {
          setUpdateItem(0);
          usersQuery.refetch();
        }}
      />
      <Delete
        id={deleteItem}
        isOpen={!!deleteItem}
        onClose={() => {
          setDeleteItem(0);
          usersQuery.refetch();
        }}
      />
    </>
  );
};
