import React, { useEffect, useState, useMemo } from 'react';
import nprogress from 'nprogress';
import { useQuery } from '@tanstack/react-query';
import { Link as LinkRouterDom, useParams, useSearchParams } from 'react-router-dom';
import { ClientCompany } from 'services';
import {
  chakra,
  useToast,
  FormLabel,
  Badge,
  Icon,
  IconButton,
  Text,
  Stack,
  HStack,
  Link,
  Divider,
} from '@chakra-ui/react';

import Read from './read';

import { Content, Paper } from 'components/Content';
import { Select } from 'components/Forms/Select';
import Result from 'components/Result';

import { RiFileAddFill, RiFileList2Fill, RiGlobalLine } from 'react-icons/ri';
import { ImBlocked } from 'react-icons/im';

import { useDelayedValue } from 'hooks/useDelayedValue';
import { date, formatDaysDistance } from 'utils/format';
import { useCan } from 'hooks/useCan';
import DefaultTable from 'components/Tables/DefaultTable';

import {
  MdSignalCellular0Bar,
  MdSignalCellular1Bar,
  MdSignalCellular2Bar,
  MdSignalCellular3Bar,
  MdSignalCellular4Bar,
} from 'react-icons/md';

import {
  MdSignalWifi0Bar,
  MdSignalWifi1Bar,
  MdSignalWifi2Bar,
  MdSignalWifi3Bar,
  MdSignalWifi4Bar,
} from 'react-icons/md';

const signals = [MdSignalWifi0Bar, MdSignalWifi1Bar, MdSignalWifi2Bar, MdSignalWifi3Bar, MdSignalWifi4Bar];

export default function ClientCompaniesLicensesPage() {
  let { id: companyId } = useParams();
  const [searchParams] = useSearchParams();
  const [cnaeLevel, setCnaeLevel] = useState(searchParams.get('cnae_level'));
  const toast = useToast();
  const createLicensesCan = useCan({ permission_role: ['CREATE_LICENSES'] });
  const getLicensesCan = useCan({ permission_role: ['GET_LICENSES'] });
  const updateLicensesCan = useCan({ permission_role: ['UPDATE_LICENSES'] });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(20);
  const [sort, setSort] = useState('et.id');
  const [direction, setDirection] = useState('desc');
  const [addItem, setAddItem] = useState({});
  const [readItem, setReadItem] = useState({});
  const [company, setCompany] = useState([]);
  const [cnpjData, setCnpjData] = useState('');
  const [cnaeData, setCnaeData] = useState('');
  const [cnpjOptions, setCnpjOptions] = useState([]);
  const [cnaeOptions, setCnaeOptions] = useState([]);
  const [searchEmpty, setSearchEmpty] = useState(false);
  const [cnpjsSelecteds, setCnpjsSelecteds] = useState([]);
  const [cnaesSelecteds, setCnaesSelecteds] = useState([]);

  const searchDelayed = useDelayedValue(search, 1000);

  const getCompany = async (companyId) => {
    try {
      const { data } = await ClientCompany.readItem(companyId);
      let cnaeOptionObjLow = data?.cnaes?.lowLevel?.map((item) => {
        return {
          id: item.id,
          value: item.code_class,
          colorScheme: 'green',
          label: (
            <span style={{ padding: '3px 0' }} title={item.code_description}>
              <Icon as={MdSignalCellular1Bar} color="green.400" w={4} h={4} me="2" title="Risco Baixo" />
              {item.code_class}
            </span>
          ),
        };
      });
      let cnaeOptionObjMedium = data?.cnaes?.mediumLevel?.map((item) => {
        return {
          id: item.id,
          value: item.code_class,
          colorScheme: 'yellow',
          label: (
            <>
              <span style={{ padding: '3px 0' }} title={item.code_description}>
                <Icon as={MdSignalCellular3Bar} color="yellow.400" w={4} h={4} me="2" title="Risco Médio" />
                {item.code_class}
              </span>
            </>
          ),
        };
      });
      let cnaeOptionObjHigh = data?.cnaes?.highLevel?.map((item) => {
        return {
          id: item.id,
          value: item.code_class,
          colorScheme: 'red',
          label: (
            <>
              <span style={{ padding: '3px 0' }} title={item.code_description}>
                <Icon as={MdSignalCellular4Bar} color="red.400" w={4} h={4} me="2" title="Risco Alto" />
                {item.code_class}
              </span>
            </>
          ),
        };
      });
      let cnaeoptions = [].concat(cnaeOptionObjLow, cnaeOptionObjMedium, cnaeOptionObjHigh);
      setCnaeOptions(cnaeoptions);
      return data;
    } catch (error) {
      toast({
        title: 'Não foi possível carregar a empresa.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const getLicenses = async (page = 0) => {
    try {
      nprogress.start();
      const { data } = await ClientCompany.licenseIndex(
        companyId,
        `?cnae_level=${cnaeLevel}&search=${searchDelayed}&page=${page}&limit=${limit}&sort=${sort}&direction=${direction}&cnaes=${cnaeData}&cnpjs=${cnpjData}`
      );
      nprogress.done();
      return data?.result[0];
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar as licenças.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const getCNPJs = async (companyId) => {
    try {
      let { data } = await ClientCompany.cnpjIndex(companyId, `&limit=500000`);
      let cnpjOptionObj = data?.items?.map((i) => {
        return {
          value: i.id,
          label:
            i.registrationCode +
            ' - ' +
            (i.systemStateAlias ? i.systemStateAlias : 'n/d') +
            ' - ' +
            (i.systemCityName ? i.systemCityName : 'n/d'),
        };
      });
      setCnpjOptions(cnpjOptionObj);
      return data;
    } catch (error) {
      toast({
        title: 'Não foi possível carregar os CNPJs.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const companyQuery = useQuery({
    queryKey: [`@eConform-getCompanyLicenses-${companyId}`, companyId],
    queryFn: () => getCompany(companyId),
    keepPreviousData: true,
  });

  const licensesQuery = useQuery({
    queryKey: [
      `@eConform-getLicenses-${companyId}`,
      companyId,
      setCnaeOptions,
      cnpjData,
      cnaeData,
      page,
      searchDelayed,
      limit,
    ],
    queryFn: () => getLicenses(page),
    keepPreviousData: true,
  });

  const cnpjsQuery = useQuery({
    queryKey: [`@eConform-getCnpjs-${companyId}`, companyId, setCnpjOptions],
    queryFn: () => getCNPJs(companyId),
    keepPreviousData: true,
  });

  const handleAddItem = (val = {}) => {
    setAddItem(val);
  };

  const handleReadItem = (val = {}) => {
    setReadItem(val);
  };

  const columns = useMemo(
    () => [
      'Status',
      'Licença',
      'CNAE referÊncia',
      'Validade',
      'Risco',
      'Complexidade',
      'Adicionado/Alterado',
      'Opções',
    ],
    []
  );

  const dataItems = licensesQuery.data?.items?.map((item) => ({
    status:
      item.status === 'in_conformity' ? (
        <Badge colorScheme="green">Em conformidade</Badge>
      ) : item.status === 'pending' ? (
        <Badge colorScheme="yellow">Pendente</Badge>
      ) : item.status === 'license_expired' ? (
        <Badge colorScheme="red">Vencida</Badge>
      ) : item.status === 'near_expiration' ? (
        <Badge colorScheme="purple">Expirando</Badge>
      ) : item.status === 'renewing' ? (
        <Badge colorScheme="blue">Renovando</Badge>
      ) : item.status === 'not_necessary' ? (
        <Badge colorScheme="grayBlue">Não necessária</Badge>
      ) : (
        <Badge colorScheme="grayBlue">Não necessária</Badge>
      ),
    fiscalLicenseName: (
      <>
        <Link
          as={LinkRouterDom}
          /* to={
            !item?.isNecessary
              ? `/client/companies/${companyId}/documents/${item.clientNationalRegistrationId}/${
                  item.fiscalLicenseName !== 'CNPJ' && item.fiscalLicenseName !== 'Cartão CNPJ'
                    ? item.clientNationalRegistrationFiscalRegistrationId +
                      (item.fiscalLicenseId
                        ? '?cnae_level=' +
                          cnaeLevel +
                          '&license=' +
                          item.fiscalLicenseId +
                          '&cnae=' +
                          item.fiscalRegistrationCodeClass
                        : '?cnae_level=' + cnaeLevel)
                    : '?cnae_level=' + cnaeLevel
                }`
              : '#'
          } */
          onClick={() => handleReadItem(item)}
          fontWeight="semibold"
          color={item?.isNecessary ? 'blue.400' : 'grayBlue.500'}
        >
          {item.fiscalLicenseName}{' '}
        </Link>
        <Text as="sup" color="grayBlue.500">
          {item.id}
        </Text>
        {/* <br />
        <Text fontSize="xs">{item.description || 'Sem descrição da licença'}</Text> */}
      </>
    ),
    fiscalRegistrationCodeClass: (
      <>
        <Text title={item.fiscalRegistrationCodeDescription} fontWeight="semibold" style={{ textWrap: 'nowrap' }}>
          {item.fiscalRegistrationCodeClass}
        </Text>
        <Text fontSize="xs">{item.fiscalRegistrationCodeDescription || 'Sem descrição da CNAE'}</Text>
      </>
    ),
    validUntil: item.validUntil ? (
      <Text title={date(item.validUntil)} textAlign="center">
        {/* <Icon as={RiGlobalLine} boxSize={3} color="grayBlue.600" /> */} {date(item.validUntil, "dd' 'MMM' 'yyyy'")}
      </Text>
    ) : item.status !== 'pending' ? (
      <Text textAlign="center">Indeterminada</Text>
    ) : (
      <Text textAlign="center">-</Text>
    ),
    fiscalLicenseImpact: (
      <Text textAlign="center">
        <Icon
          as={signals[item.fiscalLicenseImpact] || signals[0]}
          boxSize={6}
          title={
            item.fiscalLicenseImpact == '1'
              ? 'Risco baixo'
              : item.fiscalLicenseImpact == '2'
              ? 'Risco médio'
              : item.fiscalLicenseImpact == '3'
              ? 'Risco alto'
              : 'Sem risco'
          }
          color={
            item.fiscalLicenseImpact == '1'
              ? 'green.400'
              : item.fiscalLicenseImpact == '2'
              ? 'yellow.400'
              : item.fiscalLicenseImpact == '3'
              ? 'red.400'
              : 'grayBlue.600'
          }
        />
      </Text>
    ),
    fiscalLicenseComplexity: (
      <Text textAlign="center">
        <Icon
          as={signals[item.fiscalLicenseComplexity] || signals[0]}
          boxSize={6}
          title={
            item.fiscalLicenseComplexity == '1'
              ? 'Complexidade baixo'
              : item.fiscalLicenseComplexity == '2'
              ? 'Complexidade média'
              : item.fiscalLicenseComplexity == '3'
              ? 'Complexidade alta'
              : 'Sem complexidade'
          }
          color={
            item.fiscalLicenseComplexity == '1'
              ? 'green.400'
              : item.fiscalLicenseComplexity == '2'
              ? 'yellow.400'
              : item.fiscalLicenseComplexity == '3'
              ? 'red.400'
              : 'grayBlue.600'
          }
        />
      </Text>
    ),
    date: (
      <>
        {item?.createdAt?.date ? (
          <Text title={date(item.createdAt?.date)} textAlign="center">
            {date(item.createdAt?.date, "dd' 'MMM' 'yyyy'")}
            {/* {formatDaysDistance(item.createdAt?.date)} */}
          </Text>
        ) : (
          'Sem data'
        )}{' '}
        {item?.updatedAt?.date ? (
          <Text title={date(item.updatedAt?.date)} textAlign="center">
            {date(item.updatedAt?.date, "dd' 'MMM' 'yyyy'")}
            {/* {formatDaysDistance(item.updatedAt?.date)} */}
          </Text>
        ) : (
          'Não alterado'
        )}
      </>
    ),
    options: (
      <HStack spacing="2">
        {/* <IconButton
          icon={<RiFileList2Fill />}
          title="Visualizar documentos"
          aria-label="File view"
          isDisabled={!getLicensesCan}
          as={LinkRouterDom}
          to={`/client/companies/${companyId}/documents/${item.clientNationalRegistrationId}/${
            item.fiscalLicenseName !== 'CNPJ' && item.fiscalLicenseName !== 'Cartão CNPJ'
              ? item.fiscalLicenseId +
                (item.fiscalLicenseId ? '?cnae=' + item.fiscalRegistrationCodeClass : '?cnae_level=' + cnaeLevel)
              : '?cnae_level=' + cnaeLevel
          }`}
          isDisabled={!updateLicensesCan ? true : false}
          variant="ghost"
          fontSize="28"
          color="grayBlue.400"
          cursor="pointer"
          _hover={{
            color: 'blue.400',
            bgColor: 'grayBlue.200',
          }}
        /> */}
        <IconButton
          icon={<RiFileList2Fill />}
          title="Ver licença"
          aria-label="File view"
          isDisabled={!getLicensesCan || !updateLicensesCan ? true : false}
          onClick={() => handleReadItem(item)}
          variant="ghost"
          fontSize="28"
          color="grayBlue.400"
          cursor="pointer"
          _hover={{
            color: 'blue.400',
            bgColor: 'grayBlue.200',
          }}
        />
        {/* <IconButton
          icon={<RiFileAddFill />}
          title="Adicionar licença"
          aria-label="Add license"
          isDisabled={!createLicensesCan || !updateLicensesCan}
          onClick={() => handleAddItem(item)}
          variant="ghost"
          fontSize="28"
          color="grayBlue.400"
          cursor="pointer"
          _hover={{
            color: 'blue.400',
            bgColor: 'grayBlue.200',
          }}
        /> */}
      </HStack>
    ),
  }));

  /* const CnaeoptionsRequest = async () => {
    let reqCnae = await ClientCompany.cnaeIndex(companyId, `limit=500000`);
    let cnaeOptionObj = reqCnae.data.items.map((i) => {
      return {
        id: i.id,
        value: i.value,
        label: (
          <>
            {i.label}
            <Icon as={MdSignalCellular1Bar} color="green.400" w={4} h={4} ms="2" title="Risco Baixo" />
          </>
        ),
      };
    });

    setCnaeOptions(cnaeOptionObj);
  }; */

  const handleFilterCnpj = (items) => {
    let str = '';
    items?.map((item) => {
      str += `"${item.label.slice(0, 14)}",`;
    });
    setCnpjData(str.slice(0, -1));
  };

  const handleFilterCnae = (items) => {
    let str = '';
    items?.map((item) => {
      str += `"${item.value}",`;
    });
    setCnaeData(str.slice(0, -1));
  };

  /*  console.log('LICENÇAS-LISTA: ', licensesQuery?.data?.items); */
  console.log('COMPANY: ', companyQuery?.data);

  return (
    <>
      <Content>
        <Paper
          title="Licenças"
          fluid
          loading={licensesQuery.isLoading}
          fetching={licensesQuery.isFetching || licensesQuery.isRefetching}
          sizeTitle="md"
          options={
            <Stack
              direction={['column', 'column', 'row', 'row']}
              minW={['100%', '100%', '300px', '400px', '400px', '500px', '600px', '700px']}
              spacing="4"
              align="center"
            >
              <FormLabel>Filtrar </FormLabel>
              {/* <Select
                name="CNPJs"
                onChange={(e) => {
                  handleFilterCnpj(e);
                  setCnpjsSelecteds(e);
                }}
                options={cnpjOptions}
                value={cnpjsSelecteds}
                placeholder="CNPJs"
                size="sm"
              /> */}
              <Select
                name="CNAEs"
                onChange={(e) => {
                  handleFilterCnae(e);
                  setCnaesSelecteds(e);
                }}
                options={cnaeOptions}
                value={cnaesSelecteds}
                placeholder="CNAEs"
                size="sm"
              />
            </Stack>
          }
        >
          {getLicensesCan ? (
            <DefaultTable
              columns={columns}
              data={dataItems}
              page={page}
              total={licensesQuery.data?.total}
              limit={limit}
              loading={licensesQuery.isLoading}
              valueSearch={search}
              handlePageChange={(item) => {
                setPage(item);
              }}
              handleSearch={(e) => {
                if (e.key === 'Enter') {
                  setPage(0);
                  setSearch(e.target.value);
                } else {
                  setPage(0);
                  setSearch(e.target.value);
                }
              }}
              handleClearSearch={() => {
                setPage(0);
                setSearch('');
                setSearchEmpty(true);
              }}
              fetchData={() => getLicenses(0)}
              handlePerRowsChange={(event) => {
                setLimit(event.target.value);
              }}
              pagesInRange={licensesQuery.data?.pages_in_range}
            />
          ) : (
            <Result
              title="Acesso restrito"
              description="Você não tem permissão para visualizar esta listagem."
              icon={ImBlocked}
            />
          )}
        </Paper>
      </Content>

      {/* {createLicensesCan ? (
        <Create
          companyId={companyId}
          companyName={companyQuery?.data?.name}
          isOpen={Object.keys(addItem).length > 0}
          dataItem={addItem}
          onClose={() => {
            handleAddItem({});
            licensesQuery.refetch();
          }}
        />
      ) : null} */}

      {getLicensesCan ? (
        <Read
          companyId={companyId}
          companyUf={companyQuery?.data?.uf}
          licenseId={readItem.id}
          companyName={companyQuery?.data?.name}
          isOpenLicense={Object.keys(readItem).length > 0}
          dataItem={readItem}
          onCloseLicense={() => {
            handleReadItem({});
            licensesQuery.refetch();
          }}
        />
      ) : null}
    </>
  );
}
