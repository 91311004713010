import React, { useState, useMemo, useCallback, useRef } from 'react';
import nprogress from 'nprogress';
import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { ClientCompany } from 'services';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { S3Client, PutObjectCommand, DeleteObjectCommand } from '@aws-sdk/client-s3';
import { date, daysDistance, formatDaysDistance } from 'utils/format';
import { format } from 'date-fns';
import slugify from 'react-slugify';

import {
  chakra,
  Box,
  Button,
  ButtonGroup,
  Text,
  Heading,
  Icon,
  Flex,
  FormLabel,
  Image,
  useToast,
  VisuallyHidden,
  VStack,
  Spinner,
  SimpleGrid,
  GridItem,
  Grid,
  Divider,
} from '@chakra-ui/react';
import { Loading } from 'components/Loading';
import { Content, Paper } from 'components/Content';
import { Input } from 'components/Forms/Input';
import { Checkbox } from 'components/Forms/Checkbox';

import { RiRefreshLine } from 'react-icons/ri';
import { MdLocalPostOffice, MdLocalPhone } from 'react-icons/md';

import Delete from './delete';

import { useCan } from 'hooks/useCan';
import DefaultImage from 'resources/images/company-default-image.png';

const schema = Yup.object({
  name: Yup.string().required('Nome é obrigatório.'),
});

export default () => {
  let { id: companyId } = useParams();
  const [searchParams] = useSearchParams();
  const [cnaeLevel, setCnaeLevel] = useState(searchParams.get('cnae_level'));
  let navigate = useNavigate();
  const toast = useToast();
  const [loadingImg, setLoadingImg] = useState(false);
  const [lastUpdateCompany, setLastUpdateCompany] = useState();
  const [loadingUpdateCompany, setLoadingUpdateCompany] = useState(false);
  const updateCompanyCan = useCan({ permission_role: ['UPDATE_COMPANY'] });
  const deleteCompanyCan = useCan({ permission_role: ['DELETE_COMPANY'] });
  const [company, setCompany] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const inputFileImage = useRef();
  const [deleteItem, setDeleteItem] = useState(0);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(schema) });

  const getCompany = async () => {
    try {
      nprogress.start();
      const { data } = await ClientCompany.readItem(companyId);
      const response = await ClientCompany.lastUpdateDataItem(companyId);
      nprogress.done();
      setLastUpdateCompany(response.data);
      reset(data);
      return data;
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar as informações da empresa.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
      setTimeout(() => {
        navigate('/client/companies', { replace: true });
      }, 2000);
    }
  };

  const companyQuery = useQuery({
    queryKey: [`@eConform-getCompanyPanel-${companyId}`, companyId],
    queryFn: () => getCompany(),
    keepPreviousData: true,
  });

  const preview = useMemo(() => {
    return thumbnail ? URL.createObjectURL(thumbnail) : null;
  }, [thumbnail]);

  const s3 = new S3Client({
    region: process.env.REACT_APP_AWS_S3_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
    },
  });

  const handleInsertLogo = useCallback(
    async (e) => {
      setLoadingImg(true);
      try {
        e.preventDefault();
        companyQuery?.data?.logoSrc && handleDeleteLogo(companyQuery?.data?.logoSrc);

        let file = inputFileImage.current?.files[0];
        let newFileName = inputFileImage.current?.files[0].name.replace(/\s/g, '');
        const photoKey =
          process.env.REACT_APP_AWS_S3_DIR_NAME_LOGOS +
          'logo-' +
          companyQuery?.data?.id +
          '-' +
          slugify(companyQuery?.data?.name) +
          '-' +
          format(new Date(), 'yyyyMMddHHmm') +
          newFileName.slice(-4);

        ClientCompany.logoCreateItem(companyId, { file: photoKey });

        const uploadParams = {
          Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
          Key: photoKey,
          Body: file,
        };

        await s3.send(new PutObjectCommand(uploadParams));

        toast({
          title: 'Logo cadastrada',
          status: 'success',
          isClosable: true,
        });
        setThumbnail(null);
        companyQuery.refetch();
      } catch (error) {
        toast({
          title: 'Não foi possível enviar a imagem anexada.',
          description: error.response.data.message
            ? error.response.data.message
            : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
          status: 'error',
          isClosable: true,
        });
      }
      setLoadingImg(false);
    },
    [companyQuery, thumbnail]
  );

  const handleDeleteLogo = useCallback(
    async (logo) => {
      setLoadingImg(true);
      try {
        await ClientCompany.logoDeleteItem(companyId);
        const deleteParams = {
          Key: logo,
          Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
        };
        await s3.send(new DeleteObjectCommand(deleteParams));
        toast({
          title: 'Logo excluida',
          status: 'success',
          isClosable: true,
        });
        companyQuery.refetch();
      } catch (error) {
        toast({
          title: 'Não foi possível excluir a logo.',
          description: error.response.data.message
            ? error.response.data.message
            : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
          status: 'error',
          isClosable: true,
        });
      }
      setLoadingImg(false);
    },
    [companyQuery]
  );

  const putUpdateCompany = async (id) => {
    setLoadingUpdateCompany(true);
    toast({
      title: 'Atualizando empresa',
      description: (
        <>
          <Spinner thickness="2px" speed="0.4s" emptyColor="whiteAlpha.700" color="blue.400" size="sm" /> Aguarde...
        </>
      ),
      status: 'info',
      duration: 60000,
      isClosable: true,
    });
    try {
      await ClientCompany.updateDataItem(id);
      toast.closeAll();
      toast({
        title: 'Empresa atualizada',
        description:
          'Verifique os dados atualizados na ficha deinformações ou clicando na empresa e visitando a aba Dados.',
        status: 'success',
        isClosable: true,
      });
      companyQuery.refetch();
      setLoadingUpdateCompany(false);
    } catch (error) {
      setLoadingUpdateCompany(false);
      toast.closeAll();
      toast({
        title: 'Não foi possível atualizar os dados desta empresa.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const handleOnSubmit = useCallback(
    async ({ name, enabled }) => {
      try {
        await ClientCompany.updateItem(companyId, { name, enabled });
        toast({
          title: 'Empresa atualizada',
          status: 'success',
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Não foi possível alterar as informações da empresa.',
          description: error.response.data.message
            ? error.response.data.message
            : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [companyId]
  );

  return (
    <>
      <Content>
        <Paper
          title="Perfil da Empresa"
          loading={companyQuery.isLoading || loadingImg}
          sizeTitle="md"
          w={{ base: '100%', md: '50%', lg: '100%', xl: '30%' }}
        >
          <VStack align="center" mb="8">
            <Image
              src={
                preview && !loadingImg
                  ? preview
                  : companyQuery?.data?.logoSrc
                  ? process.env.REACT_APP_AWS_S3_DOMAIN +
                    process.env.REACT_APP_AWS_S3_BUCKET_NAME +
                    '/' +
                    companyQuery?.data?.logoSrc
                  : DefaultImage
              }
              w="auto"
              minH="100px"
              maxH="150px"
              border={preview ? '1px solid' : 'none'}
              borderColor="grayBlue.200"
              borderRadius="3px"
              marginX={'auto'}
              alt={`Logo da empresa da ${reset.name}`}
            />
            <VisuallyHidden>
              <Input
                type="file"
                name="file"
                accept="image/*"
                onChange={(e) => setThumbnail(e.target.files[0])}
                display="none"
                ref={inputFileImage}
              />
            </VisuallyHidden>
            <ButtonGroup variant="ghost" size="xs" spacing="4">
              <Button
                onClick={() => {
                  inputFileImage.current.click();
                }}
                isDisabled={!updateCompanyCan}
              >
                {'Trocar imagem'}
              </Button>
              {thumbnail ? (
                <>
                  <Button onClick={handleInsertLogo} colorScheme="green" isDisabled={!updateCompanyCan}>
                    Confirmar upload
                  </Button>
                  <Button onClick={() => setThumbnail(null)} colorScheme="grayBlue" isDisabled={!updateCompanyCan}>
                    Cancelar upload
                  </Button>
                </>
              ) : (
                ''
              )}
              {companyQuery?.data?.logoSrc && !thumbnail && (
                <Button
                  onClick={() => handleDeleteLogo(companyQuery?.data?.logoSrc)}
                  colorScheme="red"
                  isDisabled={!updateCompanyCan}
                >
                  Excluir imagem
                </Button>
              )}
            </ButtonGroup>
          </VStack>

          <Divider my={8} />

          <Flex direction="column" gap="8">
            <VStack as="form" id="formCompany" spacing="8" align="stretch" onSubmit={handleSubmit(handleOnSubmit)}>
              <Input name="name" label="Nome" w="100%" htmlSize={24} errors={errors} register={register} />
              <Checkbox
                name="enabled"
                label="Status"
                switchoption={true}
                options={[{ label: 'Ativo', value: 1 }]}
                register={register}
                errors={errors}
              />
            </VStack>
            <VStack ml="auto">
              <ButtonGroup spacing="4">
                <Button type="submit" form="formCompany" isDisabled={!updateCompanyCan}>
                  Salvar
                </Button>
                <Button colorScheme="red" onClick={() => setDeleteItem(companyId)} isDisabled={!deleteCompanyCan}>
                  Excluir empresa
                </Button>
              </ButtonGroup>
            </VStack>
          </Flex>
        </Paper>

        <Paper
          title="Dados"
          loading={companyQuery.isLoading}
          isSubmitting={isSubmitting}
          sizeTitle="md"
          w={{ base: '100%', md: '50%', lg: '100%', xl: '70%' }}
          options={
            <VStack>
              <Button
                leftIcon={
                  loadingUpdateCompany ? (
                    <Spinner thickness="2px" speed="0.4s" emptyColor="whiteAlpha.700" color="blue.400" size="sm" />
                  ) : (
                    <RiRefreshLine />
                  )
                }
                onClick={() => putUpdateCompany(Number(companyId))}
              >
                Atualizar dados
              </Button>
              {/* <Text fontSize="xs" color="grayBlue.600">
                Criado <strong> {formatDaysDistance(companyQuery?.data?.createdAt?.date)} </strong>
              </Text> */}

              <Text fontSize="xs" color="grayBlue.600">
                Atualizado{' '}
                <strong>
                  {' '}
                  {lastUpdateCompany?.lastUpdateInDays
                    ? `há cerca de ${lastUpdateCompany?.lastUpdateInDays} dias`
                    : 'hoje'}
                </strong>
              </Text>
            </VStack>
          }
        >
          {companyQuery?.isLoading ? (
            <Loading />
          ) : (
            <Grid templateColumns="repeat(2, 1fr)" gap={4} color="grayBlue.700" fontSize="sm" borderRadius={4}>
              <GridItem colSpan={1} border="1px solid #e4e7f2" padding={4}>
                <Heading as="h6" size="xs">
                  Razão social:
                </Heading>
                <Text mb={4}>
                  {companyQuery?.data?.registration_name == 'undefined' ||
                  companyQuery?.data?.registration_name == 'null' ||
                  companyQuery?.data?.registration_name == 'Não encontrado' ||
                  !companyQuery?.data?.registration_name
                    ? 'Não possui'
                    : companyQuery?.data?.registration_name}
                </Text>
                <Heading as="h6" size="xs">
                  Nome fantasia:
                </Heading>
                <Text mb={4}>
                  {companyQuery?.data?.registration_fantasy_name == 'undefined' ||
                  companyQuery?.data?.registration_fantasy_name == 'null' ||
                  companyQuery?.data?.registration_fantasy_name == 'Não encontrado' ||
                  !companyQuery?.data?.registration_fantasy_name
                    ? 'Não possui'
                    : companyQuery?.data?.registration_fantasy_name}
                </Text>
                <Heading as="h6" size="xs">
                  CNPJ:
                </Heading>
                <Text mb={4}>
                  {companyQuery?.data?.registration_code ? companyQuery?.data?.registration_code : 'Não cadastrado'}
                  {companyQuery?.data?.type}
                </Text>
                <Heading as="h6" size="xs">
                  Risco do CNPJ
                </Heading>
                <Text mb={4}>
                  {companyQuery?.data?.cnpjLevel === 'high_level'
                    ? 'Risco Alto'
                    : companyQuery?.data?.cnpjLevel === 'medium_level'
                    ? 'Risco Médio'
                    : companyQuery?.data?.cnpjLevel === 'low_level'
                    ? 'Risco Baixo'
                    : 'Não identificado'}
                </Text>
              </GridItem>
              <GridItem colSpan={1} border="1px solid #e4e7f2" padding={4}>
                <Heading as="h6" size="xs">
                  Situação:
                </Heading>
                <Text mb={4}>
                  {companyQuery?.data?.registration_situation === 'INAPTA'
                    ? 'SUSPENSA'
                    : companyQuery?.data?.registration_situation || 'Não cadastrado'}

                  {companyQuery?.data?.createdAt.date ? <> desde {date(companyQuery?.data?.createdAt.date)}</> : ''}
                </Text>
                <Heading as="h6" size="xs">
                  Data de abertura
                </Heading>
                <Text mb={4}>{companyQuery?.data?.date_of_stablishment}</Text>
                <Heading as="h6" size="xs">
                  Natureza legal
                </Heading>
                <Text mb={4}>{companyQuery?.data?.legal_nature}</Text>
              </GridItem>
              <GridItem colSpan={1} border="1px solid #e4e7f2" padding={4}>
                <Heading as="h6" size="xs">
                  Endereço
                </Heading>
                <Text mb={4}>
                  {companyQuery?.data?.street ? companyQuery?.data?.street : 'Não cadastrado'}
                  {companyQuery?.data?.number && ', ' + companyQuery?.data?.number}
                  {companyQuery?.data?.complement && ', ' + companyQuery?.data?.complement}
                  {companyQuery?.data?.neighborhood && ' - ' + companyQuery?.data?.neighborhood}
                </Text>
                <Heading as="h6" size="xs">
                  Cidade, Estado
                </Heading>
                <Text mb={4}>
                  {companyQuery?.data?.city_name ? companyQuery?.data?.city_name : 'Não cadastrado'}
                  {companyQuery?.data?.state_name ? `, ${companyQuery?.data?.state_name}` : ''}
                </Text>
              </GridItem>
              <GridItem colSpan={1} border="1px solid #e4e7f2" padding={4}>
                <Heading as="h6" size="xs">
                  Contato
                </Heading>
                <Text mb={4}>{companyQuery?.data?.contact_name}</Text>
                <Heading as="h6" size="xs">
                  Telefone
                </Heading>
                <Text mb={4}>
                  {companyQuery?.data?.contact_phone ? companyQuery?.data?.contact_phone : 'Telefone não cadastrado'}
                </Text>
                <Heading as="h6" size="xs">
                  Email
                </Heading>
                <Text mb={4}>
                  {companyQuery?.data?.contact_email ? companyQuery?.data?.contact_email : 'Email não cadastrado'}
                </Text>
              </GridItem>

              <GridItem colSpan={2} border="1px solid #e4e7f2" padding={4}>
                <SimpleGrid columns={2} spacing={4}>
                  <Box>
                    <Heading as="h6" size="xs">
                      Atividade primária
                    </Heading>
                    {companyQuery?.data?.primary_activity?.length > 0
                      ? companyQuery?.data?.primary_activity?.map((activity, i) => (
                          <Text key={i} mb={4}>
                            {activity.code} - <i>{activity.text}</i>
                          </Text>
                        ))
                      : 'Não encontrado'}
                  </Box>
                  <Box>
                    <Heading as="h6" size="xs">
                      Atividade secundária
                    </Heading>
                    {companyQuery?.data?.secondary_activities?.length > 0
                      ? companyQuery?.data?.secondary_activities?.map((activity, i) => (
                          <Text key={i} mb={4}>
                            {activity.code} - <i>{activity.text}</i>
                          </Text>
                        ))
                      : 'Não encontrado'}
                  </Box>
                </SimpleGrid>
              </GridItem>

              <GridItem colSpan={2} border="1px solid #e4e7f2" padding={4}>
                <SimpleGrid columns={3} spacing={4}>
                  <Box>
                    <Heading as="h6" size="xs" mb={4}>
                      CNAEs Risco Baixo
                    </Heading>
                    {companyQuery?.data?.cnaes?.lowLevel?.length > 0
                      ? companyQuery?.data?.cnaes?.lowLevel?.map((cnae, i) => (
                          <Text key={i} mb={4}>
                            {cnae.code_class} - <i>{cnae.code_description}</i>
                          </Text>
                        ))
                      : 'Nenhum'}
                  </Box>
                  <Box>
                    <Heading as="h6" size="xs" mb={4}>
                      CNAEs Risco Médio
                    </Heading>
                    {companyQuery?.data?.cnaes?.mediumLevel.length > 0
                      ? companyQuery?.data?.cnaes?.mediumLevel?.map((cnae, i) => (
                          <Text key={i} mb={4}>
                            {cnae.code_class} - <i>{cnae.code_description}</i>
                          </Text>
                        ))
                      : 'Nenhum'}
                  </Box>
                  <Box>
                    <Heading as="h6" size="xs" mb={4}>
                      CNAEs Risco Alto
                    </Heading>
                    {companyQuery?.data?.cnaes?.highLevel.length > 0
                      ? companyQuery?.data?.cnaes?.highLevel?.map((cnae, i) => (
                          <Text key={i} mb={4}>
                            {cnae.code_class} - <i>{cnae.code_description}</i>
                          </Text>
                        ))
                      : 'Nenhum'}
                  </Box>
                </SimpleGrid>
              </GridItem>

              <GridItem colSpan={2} border="1px solid #e4e7f2" padding={4}>
                <Heading as="h6" size="xs" mb={4}>
                  QSA
                </Heading>
                <SimpleGrid columns={2} spacing={4}>
                  {companyQuery?.data?.qsa?.length > 0 ? (
                    companyQuery?.data?.qsa?.map((qsa, i) => (
                      <Text key={i}>
                        {qsa?.nome} - <i>{qsa?.qual}</i>
                      </Text>
                    ))
                  ) : (
                    <Text>Sem dados de QSA</Text>
                  )}
                </SimpleGrid>
              </GridItem>
            </Grid>
          )}
        </Paper>
      </Content>
      <Delete
        id={deleteItem}
        isOpen={!!deleteItem}
        onClose={() => {
          setDeleteItem(0);
          companyQuery.refetch();
        }}
      />
    </>
  );
};
