import React, { useState, useMemo } from 'react';
import nprogress from 'nprogress';
import { ClientUser } from 'services';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'context/auth';

import { useToast, Button, Badge, IconButton, HStack, Link, Text, Avatar, Box, Icon } from '@chakra-ui/react';

import Breadcrumbs from 'components/Breadcrumbs';
import { Content, Paper } from 'components/Content';
import DefaultTable from 'components/Tables/DefaultTable';
import Result from 'components/Result';
import { useDelayedValue } from 'hooks/useDelayedValue';
import { useCan } from 'hooks/useCan';

import { RiDeleteBin2Fill, RiEdit2Fill } from 'react-icons/ri';
import { ImBlocked } from 'react-icons/im';

import { date, daysDistance } from 'utils/format';

import Create from './create';
import Delete from './delete';
import Update from './update';

export default () => {
  const toast = useToast();
  const { auth } = useAuth();
  const getUserCan = useCan({ permission_role: ['GET_USER'] });
  const createUserCan = useCan({ permission_role: ['CREATE_USER'] });
  const updateUserCan = useCan({ permission_role: ['UPDATE_USER'] });
  const deleteUserCan = useCan({ permission_role: ['DELETE_USER'] });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(20);
  const [createItem, setCreateItem] = useState(false);
  const [updateItem, setUpdateItem] = useState(0);
  const [deleteItem, setDeleteItem] = useState(0);
  const searchDelayed = useDelayedValue(search, 1000);

  const getUsers = async (page = 0) => {
    try {
      nprogress.start();
      const { data } = await ClientUser.index(`&search=${searchDelayed}&page=${page}&limit=${limit}`);
      nprogress.done();
      return data;
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar os usuários.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const clientNameStorage = localStorage?.getItem('@eConform-ClientName')
    ? localStorage?.getItem('@eConform-ClientName').replace(/ /g, '')
    : 'eConformClient';

  const usersQuery = useQuery({
    queryKey: [`@eConform-getUsers-${clientNameStorage}`, clientNameStorage, page, searchDelayed, limit],
    queryFn: () => getUsers(page),
    cacheTime: 120 * (60 * 1000),
    keepPreviousData: true,
  });

  const columns = useMemo(() => ['ID', 'Usuário', 'Tipo', 'Criação/Edição', 'Opções'], []);

  const usersData = usersQuery?.data?.items.map((item) => ({
    id: parseInt(item.id),
    nameEmail: (
      <HStack spacing="4">
        <Avatar name={item.name} bgColor="grayBlue.400" color="white" size="sm" />
        <Box>
          <Link
            color={item.enabled ? 'blue.400' : 'red.500'}
            fontWeight="semibold"
            onClick={auth.email === item.email || updateUserCan ? () => setUpdateItem(item.id) : () => {}}
          >
            {item.name}
            {!item.enabled && <Badge colorScheme="red">Inativo</Badge>}

            {daysDistance(new Date(item.createdAt?.date), 30) && <Badge colorScheme="purple">Novo</Badge>}
          </Link>
          <Text color="grayBlue.700">{item.email}</Text>
        </Box>
      </HStack>
    ),
    systemRole:
      item.systemRole === 'ECONFORM' ? (
        <Badge colorScheme="green">eConform</Badge>
      ) : item.systemRole === 'AFFINITY' ? (
        <Badge colorScheme="blue">Affinity</Badge>
      ) : item.systemRole === 'PARTNER' ? (
        <Badge colorScheme="blue">Parceiro</Badge>
      ) : item.systemRole === 'ADMIN' ? (
        <Badge colorScheme="green">Administrador</Badge>
      ) : item.systemRole === 'USER' ? (
        <Badge colorScheme="blue">Usuário</Badge>
      ) : (
        <Badge color="white">{item.systemRole || 'n/d'}</Badge>
      ),
    createdUpdatedAt: (
      <>
        <Text>{date(item.createdAt ? item.createdAt.date : '')}</Text>
        <Text>{date(item.updatedAt ? item.updatedAt.date : '')}</Text>
      </>
    ),
    options: (
      <HStack spacing="2">
        <IconButton
          icon={<RiEdit2Fill />}
          title="Editar"
          aria-label="Edit"
          variant="ghost"
          fontSize="28"
          color="grayBlue.400"
          cursor="pointer"
          _hover={{
            color: 'blue.400',
            bgColor: 'grayBlue.200',
          }}
          onClick={() => setUpdateItem(item.id)}
          isDisabled={!updateUserCan}
        />
        <IconButton
          icon={<RiDeleteBin2Fill />}
          title="Excluir"
          aria-label="Delete"
          variant="ghost"
          fontSize="28"
          color="grayBlue.400"
          cursor="pointer"
          _hover={{
            color: 'red.400',
            bgColor: 'grayBlue.200',
          }}
          onClick={() => setDeleteItem(item.id)}
          isDisabled={!deleteUserCan}
        />
      </HStack>
    ),
  }));

  return (
    <>
      <Breadcrumbs
        title="Usuários"
        pages={[{ page: localStorage.getItem('@eConform-ClientName') || 'Início', link: '/client/dashboard' }]}
      />

      <Content>
        <Paper
          title="Usuários"
          fluid
          loading={usersQuery.isLoading}
          fetching={usersQuery.isFetching || usersQuery.isRefetching}
          options={
            <Button type="button" onClick={() => setCreateItem(true)} isDisabled={!createUserCan}>
              Adicionar
            </Button>
          }
        >
          {getUserCan ? (
            <DefaultTable
              columns={columns}
              data={usersData}
              page={page}
              total={usersQuery.data?.total}
              limit={limit}
              loading={usersQuery.isLoading}
              fetching={usersQuery.isFetching || usersQuery.isRefetching}
              valueSearch={search}
              handleSearch={(e) => {
                if (e.key === 'Enter') {
                  setPage(0);
                  setSearch(e.target.value);
                } else {
                  setPage(0);
                  setSearch(e.target.value);
                }
              }}
              handleClearSearch={() => {
                setPage(0);
                setSearch('');
              }}
              fetchData={() => getUsers(0)}
              handlePerRowsChange={(event) => {
                setLimit(event.target.value);
              }}
              handlePageChange={(item) => {
                setPage(item);
              }}
              pagesInRange={usersQuery.data?.pages_in_range}
            />
          ) : (
            <Result
              title="Acesso restrito"
              description="Você não tem permissão para visualizar esta listagem."
              icon={ImBlocked}
            />
          )}
        </Paper>
      </Content>

      {createUserCan && (
        <Create
          isOpen={createItem}
          onClose={() => {
            setCreateItem(false);
            usersQuery.refetch();
          }}
        />
      )}

      {updateUserCan && (
        <Update
          id={updateItem}
          isOpen={!!updateItem}
          onClose={() => {
            setUpdateItem(0);
            usersQuery.refetch();
          }}
        />
      )}

      {deleteUserCan && (
        <Delete
          id={deleteItem}
          isOpen={!!deleteItem}
          onClose={() => {
            setDeleteItem(0);
            usersQuery.refetch();
          }}
        />
      )}
    </>
  );
};
