import React, { useState, useMemo } from 'react';
import nprogress from 'nprogress';
import { AdminCnae } from 'services';
import { useQuery } from '@tanstack/react-query';

import { useToast, Box, Badge, Button, HStack, Text, IconButton, Link } from '@chakra-ui/react';

import { useDelayedValue } from 'hooks/useDelayedValue';
import { date, daysDistance } from 'utils/format';
import { RiDeleteBin2Fill, RiEdit2Fill } from 'react-icons/ri';

import Breadcrumbs from 'components/Breadcrumbs';
import { Content, Paper } from 'components/Content';
import DefaultTable from 'components/Tables/DefaultTable';

import Create from './create';
import Update from './update';
import Delete from './delete';

export default () => {
  const toast = useToast();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(20);
  const [createItem, setCreateItem] = useState(false);
  const [updateItem, setUpdateItem] = useState(0);
  const [deleteItem, setDeleteItem] = useState(0);

  const searchDelayed = useDelayedValue(search, 1000);

  const getCNAEs = async (page = 0) => {
    try {
      nprogress.start();
      const { data } = await AdminCnae.index(`&search=${searchDelayed}&page=${page}&limit=${limit}`);
      nprogress.done();
      return data;
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar os CNAEs.',
        description: error?.response?.data?.message
          ? error?.response?.data?.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const cnaesQuery = useQuery({
    queryKey: ['@eConform-getCNAEs', page, searchDelayed, limit],
    queryFn: () => getCNAEs(page),
    keepPreviousData: true,
  });

  const columns = useMemo(() => ['ID', 'Código/Descrição', 'Criação/Edição', 'Opções'], []);

  const cnaesData = cnaesQuery?.data?.result[0]?.items?.map((item) => ({
    id: parseInt(item.id),
    codeClassCodeDescription: (
      <>
        <Link
          onClick={() => setUpdateItem(item.id)}
          color={item.enabled ? 'blue.400' : 'red.500'}
          fontWeight="semibold"
        >
          {item.codeClass}
          {!item.enabled && <Badge colorScheme="red">Inativo</Badge>}
          {item.registrationLevel === 'low_level' ? (
            <Badge colorScheme="green">Risco Baixo</Badge>
          ) : item.registrationLevel === 'medium_level' ? (
            <Badge colorScheme="yellow">Risco Médio</Badge>
          ) : item.registrationLevel === 'high_level' ? (
            <Badge colorScheme="red">Risco Alto</Badge>
          ) : (
            ''
          )}
          {daysDistance(new Date(item.createdAt?.date), 30) && <Badge colorScheme="purple">Novo</Badge>}
        </Link>
        <Text>{item.codeDescription}</Text>
      </>
    ),
    createdUpdatedAt: (
      <>
        <Text>{date(item.createdAt ? item.createdAt.date : '')}</Text>
        <Text>{date(item.updatedAt ? item.updatedAt.date : '')}</Text>
      </>
    ),
    options: (
      <HStack spacing="2">
        <IconButton
          icon={<RiEdit2Fill />}
          title="Editar"
          aria-label="Edit"
          variant="ghost"
          fontSize="28"
          color="grayBlue.400"
          cursor="pointer"
          _hover={{
            color: 'blue.400',
            bgColor: 'grayBlue.200',
          }}
          onClick={() => setUpdateItem(item.id)}
        />
        <IconButton
          icon={<RiDeleteBin2Fill />}
          title="Excluir"
          aria-label="Delete"
          variant="ghost"
          fontSize="28"
          color="grayBlue.400"
          cursor="pointer"
          _hover={{
            color: 'red.400',
            bgColor: 'grayBlue.200',
          }}
          onClick={() => setDeleteItem(item.id)}
        />
      </HStack>
    ),
  }));

  return (
    <>
      <Breadcrumbs title="CNAEs" pages={[{ page: 'Início', link: '/admin/accounts/business' }]} />

      <Content>
        <Paper
          title="CNAEs"
          fluid
          loading={cnaesQuery.isLoading}
          fetching={cnaesQuery.isFetching || cnaesQuery.isRefetching}
          options={
            <Button type="button" onClick={() => setCreateItem(true)}>
              Adicionar
            </Button>
          }
        >
          <DefaultTable
            columns={columns}
            data={cnaesData}
            page={page}
            total={cnaesQuery.data?.result[0]?.total}
            limit={limit}
            loading={cnaesQuery.isLoading}
            fetching={cnaesQuery.isFetching || cnaesQuery.isRefetching}
            valueSearch={search}
            handleSearch={(e) => {
              if (e.key === 'Enter') {
                setPage(0);
                setSearch(e.target.value);
              } else {
                setPage(0);
                setSearch(e.target.value);
              }
            }}
            handleClearSearch={() => {
              setPage(0);
              setSearch('');
            }}
            fetchData={() => getCNAEs(0)}
            handlePerRowsChange={(event) => {
              setLimit(event.target.value);
            }}
            handlePageChange={(item) => {
              setPage(item);
            }}
            pagesInRange={cnaesQuery.data?.result[0]?.pages_in_range}
          />
        </Paper>
      </Content>
      <Create
        isOpen={createItem}
        onClose={() => {
          setCreateItem(false);
          cnaesQuery.refetch();
        }}
      />
      <Update
        id={updateItem}
        isOpen={!!updateItem}
        onClose={() => {
          setUpdateItem(0);
          cnaesQuery.refetch();
        }}
      />
      <Delete
        id={deleteItem}
        isOpen={!!deleteItem}
        onClose={() => {
          setDeleteItem(0);
          cnaesQuery.refetch();
        }}
      />
    </>
  );
};
