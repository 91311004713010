import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Skeleton,
  Stack,
} from '@chakra-ui/react';
import { Loading } from 'components/Loading';

export default function BaseModal({
  title = '',
  isOpen = false,
  onClose = () => {},
  loading = false,
  isSubmitting = false,
  children = {},
  footer = {},
  size = 'md',
  ...props
}) {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size={size} {...props}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          {title}{' '}
          {(loading || isSubmitting) && (
            <Loading color="white" display="inline-block" w="auto" size="sm" py="0" ml="4" />
          )}{' '}
        </DrawerHeader>
        {loading && !isSubmitting ? (
          <DrawerBody opacity={loading ? '.2' : '1'}>
            <Stack>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          </DrawerBody>
        ) : (
          <DrawerBody opacity={isSubmitting ? '.2' : '1'}>{children}</DrawerBody>
        )}
        {footer && <DrawerFooter bg={'grayBlue.200'}>{footer}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  );
}
