import React, { useState, useEffect, useCallback, useRef } from 'react';
import nprogress from 'nprogress';
import { useQuery } from '@tanstack/react-query';
import { Link as LinkRouterDom, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { ClientCompany } from 'services';
import Geocode from 'react-geocode';

import { Loading } from 'components/Loading';
import Breadcrumbs from 'components/Breadcrumbs';
import MainTab from 'components/Tabs/MainTab';
import PieChart from 'components/Charts';
import { Content, Paper } from 'components/Content';
import { GoogleMapComponent } from 'components/GoogleMaps';

import ExportData from './export';

import { MdDashboard, MdLibraryBooks, MdAccountBalance } from 'react-icons/md';
import { RiFilePaper2Line } from 'react-icons/ri';
import { useCan } from 'hooks/useCan';
import {
  useToast,
  Box,
  Button,
  Flex,
  Stack,
  Text,
  VStack,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Icon,
  Modal as ModalChakra,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { HiChevronDown } from 'react-icons/hi';
import { useReactToPrint } from 'react-to-print';

Geocode.setApiKey('AIzaSyC7rjTylpZDrH_OAKIGSBiAK2zoHZinWdk');
Geocode.setLanguage('en');
Geocode.setRegion('es');

export default function ClientCompaniesPanelPage() {
  const componentRef = useRef();
  const toast = useToast();
  let { id: companyId } = useParams();
  let { search: argsLocation } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [chartsLicenses, setChartsLicenses] = useState([]);
  const [locationsCNPJs, setLocationsCNPJs] = useState([]);

  const getAddress = async (companyId) => {
    try {
      const { data } = await ClientCompany.addressIndex(companyId);
      data?.result?.map((location, i) => {
        setLocationsCNPJs((locationsCNPJs) => [
          ...locationsCNPJs,
          { lat: Number(location.geocodeLatitude), lng: Number(location.geocodeLongitude) },
        ]);
      });

      return data?.result;
    } catch (error) {
      toast({
        title: 'Não foi possível carregar os endereços.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const getLicensesStatus = async (companyId, argsLocation) => {
    try {
      nprogress.start();
      const { data } = await ClientCompany.licensesStatus(companyId, argsLocation);
      nprogress.done();

      setChartsLicenses(
        data?.result?.map((item, idx) => ({
          id: idx,
          type:
            item.status === 'in_conformity'
              ? 'Em conformidade'
              : item.status === 'pending'
              ? 'Pendente'
              : item.status === 'license_expired'
              ? 'Vencidas'
              : item.status === 'near_expiration'
              ? 'Expirando'
              : item.status === 'renewing'
              ? 'Renovando'
              : item.status === 'not_necessary'
              ? 'Não necessárias'
              : 'Não encontradas',
          value: item.total !== 0 ? item.total : null,
          color:
            item.status === 'in_conformity'
              ? '#2ed47a'
              : item.status === 'pending'
              ? '#ffb946'
              : item.status === 'license_expired'
              ? '#f7685b'
              : item.status === 'near_expiration'
              ? '#885AF8'
              : item.status === 'renewing'
              ? '#109CF1'
              : item.status === 'not_necessary'
              ? '#666'
              : '#000',
        }))
      );

      return data.result;
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar os status das licenças.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const addressQuery = useQuery({
    queryKey: [`@eConform-getAddress-${companyId}`, companyId, setLocationsCNPJs],
    queryFn: () => getAddress(companyId),
    keepPreviousData: true,
  });

  const licensesStatusQuery = useQuery({
    queryKey: [`@eConform-getLicensesStatus-${companyId}`, companyId, argsLocation, setChartsLicenses],
    queryFn: () => getLicensesStatus(companyId, argsLocation),
    keepPreviousData: true,
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '{}',
    copyStyles: true,
    /* fonts: {
      family: "'Roboto', sans-serif",
      source: "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap";
    }[], */
  });

  return (
    <>
      <Content>
        <Paper title="Localizações de cada CNPJ" loading={addressQuery.isLoading} sizeTitle="md">
          <GoogleMapComponent isMarkerShown markers={locationsCNPJs} />
        </Paper>
        <Paper
          title="Licenças"
          options={
            <Button
              size="xs"
              onClick={() => {
                onOpen();
              }}
            >
              Exportar dados
            </Button>
          }
          loading={licensesStatusQuery.isLoading}
          sizeTitle="md"
        >
          {!licensesStatusQuery.isLoading && (
            <>
              <Stack direction={{ base: 'column', '4xl': 'row' }} align="center" justify="center" spacing="4">
                <Box>
                  <PieChart data={chartsLicenses} name="Licenças" />
                </Box>
                <VStack align="start" justify="center">
                  {licensesStatusQuery?.data?.map((item, i) => (
                    <Text key={i}>
                      <Box
                        as="span"
                        display="inline-block"
                        width="30px"
                        height="20px"
                        marginRight="1rem"
                        borderRadius="10px"
                        verticalAlign="middle"
                        borderWidth="5px"
                        borderColor={
                          item.status === 'in_conformity'
                            ? '#2ed47a'
                            : item.status === 'pending'
                            ? '#ffb946'
                            : item.status === 'license_expired'
                            ? '#f7685b'
                            : item.status === 'near_expiration'
                            ? '#885AF8'
                            : item.status === 'renewing'
                            ? '#109CF1'
                            : item.status === 'not_necessary'
                            ? '#666'
                            : '#000'
                        }
                      ></Box>{' '}
                      <Text as="strong">{item.total}</Text>{' '}
                      {item.status === 'in_conformity'
                        ? 'Em conformidade'
                        : item.status === 'pending'
                        ? 'Pendente'
                        : item.status === 'license_expired'
                        ? 'Vencidas'
                        : item.status === 'near_expiration'
                        ? 'Expirando'
                        : item.status === 'renewing'
                        ? 'Renovando'
                        : item.status === 'not_necessary'
                        ? 'Não necessária'
                        : 'Não encontradas'}
                    </Text>
                  ))}
                </VStack>
              </Stack>
            </>
          )}
        </Paper>
      </Content>

      {/* MODAL EXPORT */}
      <ModalChakra isOpen={isOpen} onClose={onClose} size="xxl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Exportação: Dados da Empresa e Licenças</ModalHeader>
          <ModalCloseButton />
          <ModalBody bg="grayBlue.200">
            <Box mx="auto" textAlign="center">
              <Button onClick={handlePrint} size="sm" variant="link" mb="8">
                Download/Imprimir
              </Button>

              <ExportData ref={componentRef} companyId={companyId} />
            </Box>
          </ModalBody>
        </ModalContent>
      </ModalChakra>
    </>
  );
}
