import React, { useEffect, useState, useCallback, useRef } from 'react';
import nprogress from 'nprogress';
import { useQuery } from '@tanstack/react-query';
import { Link, Link as LinkRouterDom, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { ClientCompany } from 'services';
import FileViewer from 'react-file-viewer';

import ButtonSearch from 'components/ButtonSearch';
import Breadcrumbs from 'components/Breadcrumbs';
import { Content, Paper } from 'components/Content';
import Pagination from 'components/Pagination';
import Result from 'components/Result';
import ShowingItemsLegend from 'components/Tables/ShowingItemsLegend';
import ItemsPerPage from 'components/Tables/ItemsPerPage';

import {
  chakra,
  useToast,
  Button,
  Badge,
  Box,
  Icon,
  Text,
  HStack,
  Stack,
  SimpleGrid,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

import { RiMore2Fill, RiEdit2Fill, RiDeleteBin2Fill, RiDownload2Fill } from 'react-icons/ri';
import { FiImage, FiFile } from 'react-icons/fi';
import { FcFolder, FcOpenedFolder } from 'react-icons/fc';
import { ImBlocked } from 'react-icons/im';
import { BsInboxFill } from 'react-icons/bs';

import {
  GrDocumentPdf,
  GrDocumentZip,
  GrDocumentTxt,
  GrDocumentWord,
  GrDocumentCsv,
  GrDocumentExcel,
  GrDocumentPpt,
  GrDocumentVideo,
  GrDocumentSound,
  GrDocumentRtf,
} from 'react-icons/gr';

import Delete from './delete';

import { useDelayedValue } from 'hooks/useDelayedValue';
import { useCan } from 'hooks/useCan';
import { date } from 'utils/format';
import CnpjCard from '../licenses/cnpjCard';
import { useReactToPrint } from 'react-to-print';

export default function ClientCompaniesLicensesPage() {
  const componentRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [document, setDocument] = useState('');

  let { id: companyId } = useParams();
  let { search: argsLocation } = useLocation();
  const [searchParams] = useSearchParams();
  const [cnaeLevel, setCnaeLevel] = useState(searchParams.get('cnae_level'));
  let { clientNationalRegistrationId } = useParams();
  let { clientNationalRegistrationFiscalRegistrationId } = useParams();
  const toast = useToast();
  const [cnpj, setCnpj] = useState(clientNationalRegistrationId || '');
  const [cnae, setCnae] = useState(clientNationalRegistrationFiscalRegistrationId || '');
  const [cnpjName, setCnpjName] = useState('');
  const [cnaeName, setCnaeName] = useState(searchParams.get('cnae') || '');
  const getFilesCan = useCan({ permission_role: ['GET_FILES'] });
  const deleteFilesCan = useCan({ permission_role: ['DELETE_FILES'] });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(20);
  const [sort, setSort] = useState('et.id');
  const [direction, setDirection] = useState('desc');
  const [deleteItem, setDeleteItem] = useState(false);

  const searchDelayed = useDelayedValue(search, 1000);

  const getCnpj = async (clientNationalRegistrationId) => {
    try {
      if (clientNationalRegistrationId) {
        const { data } = await ClientCompany.cnpjIndex(companyId, `?search=${clientNationalRegistrationId}`);
        setCnpjName(data?.items[0].registrationName || '');
      }
    } catch (error) {}
  };

  const getFiles = async (page = 0) => {
    try {
      nprogress.start();
      const { data } = await ClientCompany.fileIndex(
        companyId,
        cnpj,
        cnae,
        `${
          argsLocation ? '&' + argsLocation.slice(1) : ''
        }&search=${searchDelayed}&page=${page}&limit=${limit}&sort=${sort}&direction=${direction}`
      );
      if (searchParams.get('cnae')) {
        setCnaeName(searchParams.get('cnae') || '');
      }
      nprogress.done();
      return data;
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar os documentos.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const filesQuery = useQuery({
    queryKey: [
      `@eConform-getFiles-${companyId}`,
      companyId,
      cnpj,
      cnae,
      page,
      argsLocation,
      argsLocation,
      searchParams,
      setCnaeName,
      searchDelayed,
      limit,
      sort,
      direction,
    ],
    queryFn: () => getFiles(page),
    keepPreviousData: true,
  });

  const handleSort = (item, direction) => {
    setSort(item.id);
    setDirection(direction);
    filesQuery.refetch();
  };

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      setPage(0);
      setSearch(e.target.value);
    } else {
      setPage(0);
      setSearch(e.target.value);
    }
  };

  const handleClearSearch = () => {
    setPage(0);
    setSearch('');
  };

  const handlePerRowsChange = (newLimit) => {
    setLimit(newLimit);
  };

  const badgeStatus =
    document?.status === 'in_conformity' ? (
      <Badge colorScheme="green">Em conformidade</Badge>
    ) : document?.status === 'pending' ? (
      <Badge colorScheme="yellow">Pendente</Badge>
    ) : document?.status === 'license_expired' ? (
      <Badge colorScheme="red">Vencida</Badge>
    ) : document?.status === 'near_expiration' ? (
      <Badge colorScheme="purple">Expirando</Badge>
    ) : document?.status === 'renewing' ? (
      <Badge colorScheme="blue">Renovando</Badge>
    ) : document?.status === 'not_necessary' ? (
      <Badge colorScheme="grayBlue">Não necessária</Badge>
    ) : (
      <Badge colorScheme="grayBlue">Não encontrado</Badge>
    );

  function IconFile({ extension }) {
    return (
      <Box mr="4" align="center">
        {extension === 'folder' ? (
          <Icon as={FcFolder} boxSize="2rem" />
        ) : extension === 'jpg' ||
          extension === 'jpeg' ||
          extension === 'png' ||
          extension === 'gif' ||
          extension === 'bmp' ||
          extension === 'tiff' ||
          extension === 'cdr' ||
          extension === 'ai' ? (
          <Icon as={FiImage} boxSize="2rem" color="orange.400" />
        ) : extension === 'pdf' ? (
          <Icon as={GrDocumentPdf} boxSize="2rem" color="red.400" sx={{ path: { stroke: 'red.500' } }} />
        ) : extension === 'zip' ? (
          <Icon as={GrDocumentZip} boxSize="2rem" color="green.500" sx={{ path: { stroke: 'green.400' } }} />
        ) : extension === 'csv' ? (
          <Icon as={GrDocumentCsv} boxSize="2rem" color="cyan.400" sx={{ path: { stroke: 'cyan.400' } }} />
        ) : extension === 'rtf' ? (
          <Icon as={GrDocumentRtf} boxSize="2rem" color="cyan.400" sx={{ path: { stroke: 'cyan.400' } }} />
        ) : extension === 'doc' || extension === 'docx' ? (
          <Icon as={GrDocumentWord} boxSize="2rem" color="blue.500" sx={{ path: { stroke: 'blue.500' } }} />
        ) : extension === 'xls' || extension === 'xlsx' ? (
          <Icon as={GrDocumentExcel} boxSize="2rem" color="green.500" sx={{ path: { stroke: 'green.400' } }} />
        ) : extension === 'ppt' || extension === 'pptx' ? (
          <Icon as={GrDocumentPpt} boxSize="2rem" color="pink.400" sx={{ path: { stroke: 'pink.400' } }} />
        ) : extension === 'mp4' ||
          extension === 'mpeg' ||
          extension === 'avi' ||
          extension === 'mpg' ||
          extension === 'mpg' ||
          extension === 'mov' ? (
          <Icon as={GrDocumentVideo} boxSize="2rem" color="purple.400" sx={{ path: { stroke: 'purple.400' } }} />
        ) : extension === 'mp3' || extension === 'wav' || extension === 'ogg' || extension === 'wma' ? (
          <Icon as={GrDocumentSound} boxSize="2rem" color="orange.400" sx={{ path: { stroke: 'orange.400' } }} />
        ) : extension === 'txt' ? (
          <Icon as={GrDocumentTxt} boxSize="2rem" color="gray.600" sx={{ path: { stroke: 'gray.600' } }} />
        ) : (
          <Icon as={FiFile} boxSize="2rem" color="gray.400" sx={{ path: { stroke: 'gray.400' } }} />
        )}
      </Box>
    );
  }

  function BoxFile({ id, name, extension, href, type, status }) {
    return (
      <HStack
        key={id}
        borderRadius="3px"
        backgroundColor="grayBlue.50"
        p="4"
        cursor="pointer"
        _hover={{ backgroundColor: 'grayBlue.200' }}
        title={name}
        align="center"
        justify="space-between"
      >
        <HStack
          align="center"
          onClick={() => {
            setDocument({ id, name, href, status, type });
            onOpen();
          }}
        >
          <IconFile extension={extension} />
          <VStack spacing="0.5" align="start">
            <Text fontSize="xs" color="grayBlue.800" fontWeight="semibold" noOfLines={3} textTransform="capitalize">
              {name}
            </Text>
          </VStack>
        </HStack>
        <Box>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<Icon as={RiMore2Fill} w={6} h={6} />}
              variant="unstyle"
              minW="auto"
              color="grayBlue.400"
              _hover={{ color: 'blue.400' }}
            />
            <MenuList>
              <MenuItem icon={<RiEdit2Fill />} onClick={() => window.open(href, '_blank')}>
                Abrir
              </MenuItem>
              <MenuItem icon={<RiDeleteBin2Fill />} onClick={() => setDeleteItem(Number(id))} color="red.500">
                Excluir
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
    );
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '{}',
    copyStyles: true,
    /* fonts: {
      family: "'Roboto', sans-serif",
      source: "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap";
    }[], */
  });

  return (
    <>
      <Content>
        <Paper
          title="Documentos"
          fluid
          loading={filesQuery.isLoading}
          fetching={filesQuery.isFetching || filesQuery.isRefetching}
          sizeTitle="md"
        >
          {getFilesCan && (
            <>
              <Stack direction={{ base: 'column', md: 'row' }} mb="8" justify="space-between">
                <Stack direction={{ base: 'column', md: 'row' }} spacing="8" align="center">
                  <ButtonSearch
                    loading={filesQuery.isLoading}
                    valueSearch={search}
                    total={filesQuery.data?.total > 0}
                    handleSearch={handleSearch}
                    handleClearSearch={handleClearSearch}
                    fetchData={() => getFiles(0)}
                  />
                  <Breadcrumbs
                    title={
                      cnpjName && !cnaeName ? (
                        <>
                          <Icon as={FcOpenedFolder} boxSize="1rem" mr="1" textTransform="capitalize" />{' '}
                          {typeof cnpjName === 'string' ? cnpjName.toLowerCase() : cnpjName}
                        </>
                      ) : cnpjName && cnaeName ? (
                        <>
                          <Icon as={FcOpenedFolder} boxSize="1rem" mr="1" textTransform="capitalize" />{' '}
                          {typeof cnaeName === 'string' ? cnaeName.toLowerCase() : cnaeName}
                        </>
                      ) : (
                        ''
                      )
                    }
                    pages={[
                      {
                        page: 'Início',
                        link: `/client/companies/${companyId}/documents?cnae_level=${cnaeLevel}`,
                        onclick: () => {
                          setCnpj(''), setCnae(''), setCnpjName(''), setCnaeName('');
                        },
                      },
                      {
                        page:
                          cnpjName && cnaeName ? (
                            <>
                              <Icon as={FcOpenedFolder} boxSize="1rem" mr="1" textTransform="capitalize" />{' '}
                              {typeof cnpjName === 'string' ? cnpjName.toLowerCase() : cnpjName}
                            </>
                          ) : null,
                        link: `/client/companies/${companyId}/documents/${cnpj}?cnae_level=${cnaeLevel}`,
                        onclick: () => {
                          setCnae(''), setCnaeName('');
                        },
                      },
                    ]}
                    h="auto"
                  />
                </Stack>
                {filesQuery.data?.items && filesQuery.data?.total > 0 && !filesQuery.isLoading && (
                  <Stack direction={{ base: 'column', md: 'row' }} spacing="8" align="center">
                    <ShowingItemsLegend total={filesQuery.data?.total} limit={limit} page={page} />
                    <Pagination
                      page={page}
                      handlePageChange={handlePageChange}
                      pagesInRange={filesQuery.data.page_in_range}
                    />
                  </Stack>
                )}
              </Stack>

              {cnpj !== '' && cnae === '' && (
                <Box
                  w={['100%', '100%', '50%', '50%', '50%', '33,33%', '25%', '25%', '20%', '16,66%']}
                  pe={'15px'}
                  mb={4}
                >
                  <BoxFile id={0} name={'Cartão CNPJ'} extension={'pdf'} href={'#'} type={'document'} />
                </Box>
              )}

              {filesQuery.data?.items && filesQuery.data?.total > 0 && !filesQuery.isLoading ? (
                <>
                  <SimpleGrid columns={[1, 1, 2, 2, 2, 3, 4, 4, 5, 6]} spacing="4">
                    {filesQuery.data?.items?.map(
                      (doc) =>
                        !doc?.name?.includes('CNPJ') && (
                          <HStack
                            key={doc.id}
                            borderRadius="3px"
                            backgroundColor="grayBlue.50"
                            p="4"
                            cursor="pointer"
                            _hover={{ backgroundColor: 'grayBlue.200' }}
                            title={doc.name}
                            align="center"
                            justify="space-between"
                          >
                            <HStack
                              align="center"
                              onClick={() => {
                                if (doc.type !== 'folder') {
                                  /* return window.open(doc.href, '_blank'); */
                                  setDocument(doc);
                                  onOpen();
                                } else if (!cnpj) {
                                  setCnpj(doc.id);
                                  setCnpjName(
                                    doc.type === 'folder' && doc.name.length > 9 ? doc.name.slice(20) : doc.name
                                  );
                                } else {
                                  setCnae(doc.id);
                                  setCnaeName(doc.name);
                                }
                              }}
                            >
                              <Box mr="4" align="center">
                                {doc.type === 'folder' ? (
                                  <Icon as={FcFolder} boxSize="2rem" />
                                ) : doc.type === 'jpg' ||
                                  doc.type === 'jpeg' ||
                                  doc.type === 'png' ||
                                  doc.type === 'gif' ||
                                  doc.type === 'bmp' ||
                                  doc.type === 'tiff' ||
                                  doc.type === 'cdr' ||
                                  doc.type === 'ai' ? (
                                  <Icon as={FiImage} boxSize="2rem" color="orange.400" />
                                ) : doc.type === 'pdf' ? (
                                  <Icon
                                    as={GrDocumentPdf}
                                    boxSize="2rem"
                                    color="red.400"
                                    sx={{ path: { stroke: 'red.500' } }}
                                  />
                                ) : doc.type === 'zip' ? (
                                  <Icon
                                    as={GrDocumentZip}
                                    boxSize="2rem"
                                    color="green.500"
                                    sx={{ path: { stroke: 'green.400' } }}
                                  />
                                ) : doc.type === 'csv' ? (
                                  <Icon
                                    as={GrDocumentCsv}
                                    boxSize="2rem"
                                    color="cyan.400"
                                    sx={{ path: { stroke: 'cyan.400' } }}
                                  />
                                ) : doc.type === 'rtf' ? (
                                  <Icon
                                    as={GrDocumentRtf}
                                    boxSize="2rem"
                                    color="cyan.400"
                                    sx={{ path: { stroke: 'cyan.400' } }}
                                  />
                                ) : doc.type === 'doc' || doc.type === 'docx' ? (
                                  <Icon
                                    as={GrDocumentWord}
                                    boxSize="2rem"
                                    color="blue.400"
                                    sx={{ path: { stroke: 'blue.400' } }}
                                  />
                                ) : doc.type === 'xls' || doc.type === 'xlsx' ? (
                                  <Icon
                                    as={GrDocumentExcel}
                                    boxSize="2rem"
                                    color="green.500"
                                    sx={{ path: { stroke: 'green.400' } }}
                                  />
                                ) : doc.type === 'ppt' || doc.type === 'pptx' ? (
                                  <Icon
                                    as={GrDocumentPpt}
                                    boxSize="2rem"
                                    color="pink.400"
                                    sx={{ path: { stroke: 'pink.400' } }}
                                  />
                                ) : doc.type === 'mp4' ||
                                  doc.type === 'mpeg' ||
                                  doc.type === 'avi' ||
                                  doc.type === 'mpg' ||
                                  doc.type === 'mpg' ||
                                  doc.type === 'mov' ? (
                                  <Icon
                                    as={GrDocumentVideo}
                                    boxSize="2rem"
                                    color="purple.400"
                                    sx={{ path: { stroke: 'purple.400' } }}
                                  />
                                ) : doc.type === 'mp3' ||
                                  doc.type === 'wav' ||
                                  doc.type === 'ogg' ||
                                  doc.type === 'wma' ? (
                                  <Icon
                                    as={GrDocumentSound}
                                    boxSize="2rem"
                                    color="orange.400"
                                    sx={{ path: { stroke: 'orange.400' } }}
                                  />
                                ) : doc.type === 'txt' ? (
                                  <Icon
                                    as={GrDocumentTxt}
                                    boxSize="2rem"
                                    color="gray.600"
                                    sx={{ path: { stroke: 'gray.600' } }}
                                  />
                                ) : (
                                  <Icon
                                    as={FiFile}
                                    boxSize="2rem"
                                    color="gray.400"
                                    sx={{ path: { stroke: 'gray.400' } }}
                                  />
                                )}
                              </Box>
                              <VStack spacing="0.5" align="start">
                                <Text
                                  fontSize="xs"
                                  color="grayBlue.800"
                                  fontWeight="semibold"
                                  noOfLines={3}
                                  textTransform="capitalize"
                                >
                                  {/* {doc.type === 'folder' && doc.name.length > 9
                                ? doc.name.slice(20).toString().toLowerCase()
                                : doc.name.toString().toLowerCase()} */}
                                  {doc.registrationCode ? doc.name.slice(20) : doc.name}
                                </Text>
                                {doc.type !== 'folder' && (
                                  <Text fontSize="x-small" noOfLines={1}>
                                    {doc.fiscalLicenseName}
                                  </Text>
                                )}
                                {doc.type !== 'folder' && (
                                  <Text fontSize="x-small">
                                    Validade:{' '}
                                    {doc?.validUntil && doc?.validUntil?.date
                                      ? date(doc.validUntil?.date)
                                      : 'Indeterminada'}
                                  </Text>
                                )}
                              </VStack>
                            </HStack>
                            <Box>
                              <Menu>
                                <MenuButton
                                  as={IconButton}
                                  aria-label="Options"
                                  icon={<Icon as={RiMore2Fill} w={6} h={6} />}
                                  variant="unstyle"
                                  minW="auto"
                                  color="grayBlue.400"
                                  _hover={{ color: 'blue.400' }}
                                />
                                <MenuList>
                                  <MenuItem
                                    icon={<RiEdit2Fill />}
                                    onClick={() => {
                                      if (doc.type !== 'folder') {
                                        return window.open(doc.href, '_blank');
                                      } else if (!cnpj) {
                                        setCnpj(doc.id);
                                        setCnpjName(
                                          doc.type === 'folder' && doc.name.length > 9 ? doc.name.slice(20) : doc.name
                                        );
                                      } else {
                                        setCnae(doc.id);
                                        setCnaeName(doc.name);
                                      }
                                    }}
                                  >
                                    Abrir
                                  </MenuItem>
                                  <MenuItem
                                    icon={<RiDeleteBin2Fill />}
                                    onClick={() => setDeleteItem(Number(doc.id))}
                                    color="red.500"
                                  >
                                    Excluir
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Box>
                          </HStack>
                        )
                    )}
                  </SimpleGrid>
                  <Stack
                    direction={{ base: 'column', md: 'row' }}
                    mt="8"
                    spacing="4"
                    align="center"
                    justify="space-between"
                  >
                    <ItemsPerPage
                      total={filesQuery.data?.total}
                      limit={limit}
                      handlePerRowsChange={handlePerRowsChange}
                    />
                    <HStack direction={{ base: 'column', md: 'row' }} spacing="8" align="center" justify="end">
                      <ShowingItemsLegend total={filesQuery.data?.total} limit={limit} page={page} />
                      <Pagination
                        page={page}
                        handlePageChange={handlePageChange}
                        pagesInRange={filesQuery.data?.page_in_range}
                      />
                    </HStack>
                  </Stack>
                </>
              ) : filesQuery.data?.total == 0 && !filesQuery.isLoading ? (
                <Result
                  title="Vazio"
                  description="Os documentos aparecerão após um CNPJ ser adicionado ou serem feitos uploads em licenças."
                  icon={BsInboxFill}
                />
              ) : (
                <Result
                  title="Acesso restrito"
                  description="Você não tem permissão para visualizar esta listagem."
                  icon={ImBlocked}
                />
              )}
            </>
          )}
        </Paper>
      </Content>

      <Modal isOpen={isOpen} onClose={onClose} size="xxl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <span>
                {document?.type === 'document' ? 'Licença' : document?.type === 'protocol' ? 'Protocolo' : 'Taxa'}:{' '}
              </span>
              <IconFile extension={document?.name?.includes('CNPJ') ? 'pdf' : document?.name?.split('.').pop()} />

              <span>
                {document?.name} {badgeStatus}
              </span>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody bg="grayBlue.200">
            <Box mx="auto" textAlign="center">
              {document?.name?.includes('CNPJ') ? (
                <>
                  <Text mb="6" textAlign="left" color="blue.500" textTransform="capitalize">
                    <Button onClick={handlePrint} size="sm" variant="link">
                      Download/Imprimir
                    </Button>
                  </Text>
                  <CnpjCard ref={componentRef} companyId={companyId} document={document} />
                </>
              ) : (
                <>
                  <Text mb="6" color="blue.500" textTransform="capitalize" textAlign="left">
                    <Button as={Link} to={document?.href} target="_blank" size="sm" variant="link">
                      Download
                    </Button>
                  </Text>
                  <FileViewer
                    fileType={document?.name?.split('.').pop()}
                    filePath={document?.href}
                    styles={{ width: 'auto!important' }}
                  />
                </>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      {deleteFilesCan ? (
        <Delete
          id={deleteItem}
          companyId={companyId}
          isOpen={!!deleteItem}
          onClose={() => {
            setDeleteItem(0);
            filesQuery.refetch();
          }}
        />
      ) : null}
    </>
  );
}
