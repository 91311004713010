import React, { useState } from 'react';
import nprogress from 'nprogress';
import { ClientDashboard } from 'services';
import { ClientNotifications } from 'services';
import { useQuery } from '@tanstack/react-query';

import {
  chakra,
  Box,
  Stack,
  VStack,
  HStack,
  Heading,
  Icon,
  Text,
  useToast,
  Grid,
  GridItem,
  Button,
} from '@chakra-ui/react';
import Breadcrumbs from 'components/Breadcrumbs';
import { Paper } from 'components/Content';
import PieChart from 'components/Charts';

import { date, daysDistance } from 'utils/format';
import { RiStore2Line, RiFileList3Line, RiFileCopy2Line, RiAlertFill, RiAlarmWarningFill } from 'react-icons/ri';

import { GoogleMapComponent } from 'components/GoogleMaps';

export default function DashboardPage() {
  const toast = useToast();
  const [locationsCNPJs, setLocationsCNPJs] = useState([]);

  const getNotifications = async () => {
    try {
      const { data } = await ClientNotifications.index(``);
      return data;
    } catch (error) {
      toast({
        title: 'Não foi possível carregar as notificações.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const getKPIs = async () => {
    try {
      nprogress.start();
      const { data } = await ClientDashboard.index(``);
      nprogress.done();
      console.log('KPI: ', data);
      data?.address?.map((location, i) => {
        setLocationsCNPJs((locationsCNPJs) => [
          ...locationsCNPJs,
          { lat: Number(location.geocode_latitude), lng: Number(location.geocode_longitude) },
        ]);
      });
      return data;
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar os dados.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const clientNameStorage = localStorage?.getItem('@eConform-ClientName')
    ? localStorage?.getItem('@eConform-ClientName').replace(/ /g, '')
    : 'eConformClient';

  const kpisQuery = useQuery({
    queryKey: [`@eConform-getKPIs-${clientNameStorage}`, clientNameStorage],
    queryFn: () => getKPIs(),
    cacheTime: 120 * (60 * 1000),
    keepPreviousData: true,
  });
  const notificationsQuery = useQuery({
    queryKey: [`@eConform-getNotifications-${clientNameStorage}`, clientNameStorage],
    queryFn: () => getNotifications(),
    cacheTime: 120 * (60 * 1000),
    keepPreviousData: true,
  });

  var dataLicenses = [
    {
      type: 'Em conformidade',
      value: kpisQuery.data?.licenses?.in_conformity || 0,
      color: '#2ed47a',
    },
    {
      type: 'Pendente',
      value: kpisQuery.data?.licenses?.pending || 0,
      color: '#ffb946',
    },
    {
      type: 'Vencidas',
      value: kpisQuery.data?.licenses?.license_expired || 0,
      color: '#f7685b',
    },
    {
      type: 'Expirando',
      value: kpisQuery.data?.licenses?.near_expiration || 0,
      color: '#885AF8',
    },
    {
      type: 'Renovando',
      value: kpisQuery.data?.licenses?.renewing || 0,
      color: '#109CF1',
    },
    {
      type: 'Não necessárias',
      value: kpisQuery.data?.licenses?.not_necessary || 0,
      color: '#666',
    },
    {
      type: 'Não encontradas',
      value: 0,
      color: '#000',
    },
  ];

  var dataSituations = [
    {
      type: 'Ativas',
      value: kpisQuery.data?.situations?.active || 0,
      color: '#2ed47a',
    },
    {
      type: 'Suspensas',
      value: kpisQuery.data?.situations?.suspended || 0,
      color: '#ffb946',
    },
    {
      type: 'Baixadas',
      value: kpisQuery.data?.situations?.closed || 0,
      color: '#f7685b',
    },
  ];

  const ItemLicense = ({ name, value }) => {
    return (
      <Text>
        <Box
          as="span"
          display="inline-block"
          width="30px"
          height="20px"
          marginRight="1rem"
          borderRadius="10px"
          verticalAlign="middle"
          borderWidth="5px"
          borderColor={
            name === 'in_conformity'
              ? '#2ed47a'
              : name === 'pending'
              ? '#ffb946'
              : name === 'license_expired'
              ? '#f7685b'
              : name === 'near_expiration'
              ? '#885AF8'
              : name === 'renewing'
              ? '#109CF1'
              : name === 'not_necessary'
              ? '#666'
              : '#000'
          }
        ></Box>{' '}
        <Text as="strong">{value}</Text>{' '}
        {name === 'in_conformity'
          ? 'Em conformidade'
          : name === 'pending'
          ? 'Pendente'
          : name === 'license_expired'
          ? 'Vencidas'
          : name === 'near_expiration'
          ? 'Expirando'
          : name === 'renewing'
          ? 'Renovando'
          : name === 'not_necessary'
          ? 'Não necessária'
          : 'Não encontradas'}
      </Text>
    );
  };

  const ItemDocument = ({ name, value }) => {
    return (
      <Text>
        <Box
          as="span"
          display="inline-block"
          width="30px"
          height="20px"
          marginRight="1rem"
          borderRadius="10px"
          verticalAlign="middle"
          borderWidth="5px"
          borderColor={
            name === 'active'
              ? '#2ed47a'
              : name === 'inactive' || name === 'suspended' || name === 'inapta'
              ? '#ffb946'
              : name === 'closed'
              ? '#f7685b'
              : '#ccc'
          }
        ></Box>{' '}
        <Text as="strong">{value}</Text>{' '}
        {name === 'active'
          ? 'Ativas'
          : name === 'inactive' || name === 'suspended' || name === 'inapta'
          ? 'Suspensas'
          : name === 'closed'
          ? 'Baixadas'
          : 'Não definida'}
      </Text>
    );
  };

  const handleReadNotification = async (e, id) => {
    try {
      await ClientNotifications.updateItem(id);
      notificationsQuery.refetch();
    } catch (error) {
      toast({
        title: 'Não foi possível marcar esta notificação.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Breadcrumbs
        title="Início"
        pages={[{ page: localStorage.getItem('@eConform-ClientName') || '', link: '/client/dashboard' }]}
      />

      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)',
          xl: 'repeat(3, 1fr)',
          '4xl': 'repeat(9, 1fr)',
        }}
        templateRows={{
          base: notificationsQuery.data?.total > 0 ? 'repeat(12, 1fr)' : 'repeat(5, 1fr)',
        }}
        gap={{
          base: 4,
          xl: 8,
        }}
      >
        <GridItem colSpan={[1, 1, 1, 3]} rowSpan={notificationsQuery.data?.total > 0 ? 12 : 2}>
          <Paper title="Notificações" loading={kpisQuery.isLoading} fluid>
            <Box
              overflowY={'auto'}
              maxHeight="1580px"
              pe="3"
              sx={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'grayBlue.200',
                  borderRadius: '5px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: 'blue.400',
                },
              }}
            >
              {notificationsQuery.data?.total > 0 ? (
                notificationsQuery.data?.items?.map((notification) => (
                  <Box
                    key={notification.id}
                    bg={notification.is_read ? 'grayBlue.50' : 'grayBlue.300'}
                    rounded="4"
                    mb="4"
                    p="4"
                  >
                    <Heading as="h4" size="sm" mb="2" color="grayBlue.600" fontWeight="600">
                      <Icon
                        as={notification.type === 'alert' ? RiAlertFill : RiAlarmWarningFill}
                        color={notification.type === 'alert' ? 'orange.400' : 'red.400'}
                        me="4"
                      />
                      {notification.title}
                    </Heading>
                    <Text fontSize="sm" mt="3" mb="3" wordBreak="break-all">
                      {notification.description}
                    </Text>
                    <Text fontSize="sm" mt="3" mb="3" textTransform="capitalize" color="grayBlue.500">
                      {notification.name}
                      <small> - CNPJ: {notification.registration_code}</small>
                    </Text>
                    <HStack justifyContent="space-between">
                      <Text fontSize="xs" color="grayBlue.500">
                        {' '}
                        Notificação de {date(notification.created_at) || 'n/d'}
                      </Text>
                      {!notification?.is_read ? (
                        <Button variant="ghost" size="xs" onClick={(e) => handleReadNotification(e, notification.id)}>
                          Marcar como lida
                        </Button>
                      ) : (
                        <Text fontSize="xs" color="grayBlue.500">
                          Lida
                        </Text>
                      )}
                    </HStack>
                  </Box>
                ))
              ) : (
                <Text>Não há notificações no momento.</Text>
              )}
            </Box>
          </Paper>
        </GridItem>

        <GridItem colSpan={[1, 1, 1, 2]} rowSpan={1}>
          <Paper
            loading={kpisQuery.isLoading}
            padding={{
              base: '4',
              xl: '8',
            }}
            fluid
          >
            <HStack>
              <Icon as={RiStore2Line} boxSize={12} color="blue.400" marginRight="4" />
              <Box>
                <Heading size="xs" color="grayBlue.500">
                  Empresas cadastradas
                </Heading>
                <Text fontSize="2xl" fontWeight="semibold" color="grayBlue.600">
                  {kpisQuery.data?.companies}
                </Text>
              </Box>
            </HStack>
          </Paper>
        </GridItem>
        <GridItem colSpan={[1, 1, 1, 2]} rowSpan={1}>
          <Paper
            loading={kpisQuery.isLoading}
            padding={{
              base: '4',
              xl: '8',
            }}
            fluid
          >
            <HStack>
              <Icon as={RiFileCopy2Line} boxSize={12} color="blue.400" marginRight="4" />
              <Box>
                <Heading size="xs" color="grayBlue.500">
                  Documentos enviados
                </Heading>
                <Text fontSize="2xl" fontWeight="semibold" color="grayBlue.600">
                  {kpisQuery.data?.documents}
                </Text>
              </Box>
            </HStack>
          </Paper>
        </GridItem>
        <GridItem colSpan={[1, 1, 1, 2]} rowSpan={1}>
          <Paper
            loading={kpisQuery.isLoading}
            padding={{
              base: '4',
              xl: '8',
            }}
            fluid
          >
            <HStack>
              <Icon as={RiFileList3Line} boxSize={12} color="blue.400" marginRight="4" />
              <Box>
                <Heading size="xs" color="grayBlue.500">
                  CNPJs adicionados
                </Heading>
                <Text fontSize="2xl" fontWeight="semibold" color="grayBlue.600">
                  {kpisQuery.data?.cnpjs?.count}
                </Text>
              </Box>
            </HStack>
          </Paper>
        </GridItem>

        <GridItem colSpan={[1, 1, 1, 2]} rowSpan={1}>
          <Paper
            loading={kpisQuery.isLoading}
            padding={{
              base: '4',
              xl: '8',
            }}
            fluid
          >
            <HStack>
              <Icon as={RiFileList3Line} boxSize={12} color="green.400" marginRight="4" />
              <Box>
                <Heading size="xs" color="grayBlue.500">
                  CNPJs de Risco Baixo
                </Heading>
                <Text fontSize="2xl" fontWeight="semibold" color="green.400">
                  {kpisQuery.data?.cnpjs?.low_level}
                  <chakra.span fontSize="sm" color="gray.400" marginStart={4}>
                    {((kpisQuery.data?.cnpjs?.low_level * 100) / kpisQuery.data?.cnpjs?.count).toFixed(1)}%
                  </chakra.span>
                </Text>
              </Box>
            </HStack>
          </Paper>
        </GridItem>
        <GridItem colSpan={[1, 1, 1, 2]} rowSpan={1}>
          <Paper
            loading={kpisQuery.isLoading}
            padding={{
              base: '4',
              xl: '8',
            }}
            fluid
          >
            <HStack>
              <Icon as={RiFileList3Line} boxSize={12} color="yellow.400" marginRight="4" />
              <Box>
                <Heading size="xs" color="grayBlue.500">
                  CNPJs de Risco Médio
                </Heading>
                <Text fontSize="2xl" fontWeight="semibold" color="yellow.400">
                  {kpisQuery.data?.cnpjs?.medium_level}
                  <chakra.span fontSize="sm" color="gray.400" marginStart={4}>
                    {((kpisQuery.data?.cnpjs?.medium_level * 100) / kpisQuery.data?.cnpjs?.count).toFixed(1)}%
                  </chakra.span>
                </Text>
              </Box>
            </HStack>
          </Paper>
        </GridItem>
        <GridItem colSpan={[1, 1, 1, 2]} rowSpan={1}>
          <Paper
            loading={kpisQuery.isLoading}
            padding={{
              base: '4',
              xl: '8',
            }}
            fluid
          >
            <HStack>
              <Icon as={RiFileList3Line} boxSize={12} color="red.400" marginRight="4" />
              <Box>
                <Heading size="xs" color="grayBlue.500">
                  CNPJs de Risco Alto
                </Heading>
                <Text fontSize="2xl" fontWeight="semibold" color="red.400">
                  {kpisQuery.data?.cnpjs?.high_level}
                  <chakra.span fontSize="sm" color="gray.400" marginStart={4}>
                    {((kpisQuery.data?.cnpjs?.high_level * 100) / kpisQuery.data?.cnpjs?.count).toFixed(1)}%
                  </chakra.span>
                </Text>
              </Box>
            </HStack>
          </Paper>
        </GridItem>

        <GridItem colSpan={[1, 1, 1, 3]} rowSpan={6}>
          <Paper title="Licenças" loading={kpisQuery.isLoading} fluid>
            {!kpisQuery.isLoading && (
              <>
                <Stack direction={{ base: 'column', '4xl': 'column' }} align="center" justify="center" spacing="4">
                  <Box w="100%">
                    <PieChart data={dataLicenses} name="Licenças" />
                  </Box>
                  <VStack align="start" justify="center">
                    <ItemLicense name="in_conformity" value={kpisQuery.data.licenses?.in_conformity} />
                    <ItemLicense name="pending" value={kpisQuery.data.licenses?.pending} />
                    <ItemLicense name="license_expired" value={kpisQuery.data.licenses?.license_expired} />
                    <ItemLicense name="near_expiration" value={kpisQuery.data.licenses?.near_expiration} />
                    <ItemLicense
                      name="renewing"
                      value={kpisQuery.data.licenses?.renewing ? kpisQuery.data.licenses?.renewing : '0'}
                    />
                    <ItemLicense
                      name="not_necessary"
                      value={kpisQuery.data.licenses?.not_necessary ? kpisQuery.data.licenses?.not_necessary : '0'}
                    />
                  </VStack>
                </Stack>
              </>
            )}
          </Paper>
        </GridItem>

        <GridItem colSpan={[1, 1, 1, 3]} rowSpan={6}>
          <Paper title="Situação das empresas" loading={kpisQuery.isLoading} fluid>
            {!kpisQuery.isLoading && (
              <>
                <Stack direction={{ base: 'column', '4xl': 'column' }} align="center" justify="center" spacing="4">
                  <Box w="100%">
                    <PieChart data={dataSituations} name="Empresas" />
                  </Box>
                  <VStack align="start" justify="center">
                    <ItemDocument name="active" value={kpisQuery.data.situations?.active} />
                    <ItemDocument name="suspended" value={kpisQuery.data.situations?.suspended} />
                    <ItemDocument name="closed" value={kpisQuery.data.situations?.closed} />
                  </VStack>
                </Stack>
              </>
            )}
          </Paper>
        </GridItem>

        <GridItem colSpan={notificationsQuery.data?.total > 0 ? [1, 1, 1, 6] : [1, 1, 1, 3]} rowSpan={6}>
          <Paper title="Localizações" loading={kpisQuery.isLoading} fluid>
            <GoogleMapComponent isMarkerShown markers={locationsCNPJs} height="700px" />
          </Paper>
        </GridItem>
      </Grid>
    </>
  );
}
