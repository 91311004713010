export default {
  global: {
    body: {
      background: 'gray.50',
      height: '100%',
      color: 'gray.700',
      overflowY: 'scroll',
    },
    a: {
      textDecoration: 'none',
      _focus: {
        boxShadow: 'none!important',
      },
    },
    ul: {
      listStyle: 'none',
    },
    iframe: {
      display: 'none',
    },
    canvas: {
      margin: '0 auto',
    },
    '.pg-viewer-wrapper': {
      maxWidth: '1280px',
      overflow: 'auto',
    },
  },
};
