import { ptBR } from 'date-fns/locale';
import { parseISO, format, formatDistance, formatDistanceToNowStrict } from 'date-fns';

const date = (date, formatDate = "dd' 'MMM' 'yyyy' às 'H'h'mm") => {
  if (!date || 0 === date.length) {
    return '-';
  }

  return format(parseISO(date), formatDate, { locale: ptBR });
};

const daysDistance = (date, days = 0) => {
  let compare = formatDistanceToNowStrict(date, {
    unit: 'day',
    roundingMethod: 'ceil',
  });
  let compare1 = compare.replace(' days', '');
  let compare2 = compare1.replace(' day', '');

  const result = Number(compare2) <= days;
  return result;
};

const formatDaysDistance = (date) => {
  return formatDistance(new Date(date), new Date(), { addSuffix: true, locale: ptBR });
};

const formatCNPJ = (cnpj) => {
  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

export { date, daysDistance, formatDaysDistance, formatCNPJ };
