import React, { useState, useMemo } from 'react';
import { ClientUser } from 'services';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'context/auth';

import {
  chakra,
  useToast,
  Button,
  Badge,
  IconButton,
  HStack,
  Link,
  Text,
  Avatar,
  Box,
  Icon,
  Stack,
  SimpleGrid,
  VStack,
  Heading,
} from '@chakra-ui/react';

import Breadcrumbs from 'components/Breadcrumbs';
import { Content, Paper } from 'components/Content';
import Result from 'components/Result';
import { useDelayedValue } from 'hooks/useDelayedValue';
import { useCan } from 'hooks/useCan';

import { RiDeleteBin2Fill, RiEdit2Fill } from 'react-icons/ri';
import { ImBlocked } from 'react-icons/im';

import { date, daysDistance } from 'utils/format';

import ShowingItemsLegend from 'components/Tables/ShowingItemsLegend';
import Pagination from 'components/Pagination';
import ItemsPerPage from 'components/Tables/ItemsPerPage';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { BsInboxFill } from 'react-icons/bs';
import ButtonSearch from 'components/ButtonSearch';

export default () => {
  const toast = useToast();
  const { auth } = useAuth();
  const updateUserCan = useCan({ permission_role: ['UPDATE_USER'] });
  const deleteUserCan = useCan({ permission_role: ['DELETE_USER'] });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(20);
  const [createItem, setCreateItem] = useState(false);
  const [updateItem, setUpdateItem] = useState(0);
  const [deleteItem, setDeleteItem] = useState(0);
  const searchDelayed = useDelayedValue(search, 1000);

  const getLogs = async (page = 0) => {
    try {
      const { data } = await ClientUser.logs(`&page=${page}&limit=${limit}`);
      return data;
    } catch (error) {
      toast({
        title: 'Não foi possível carregar os logs de usuários.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const clientNameStorage = localStorage?.getItem('@eConform-ClientName')
    ? localStorage?.getItem('@eConform-ClientName').replace(/ /g, '')
    : 'eConformClient';

  const logsQuery = useQuery({
    queryKey: [`@eConform-getLogs-${clientNameStorage}`, clientNameStorage, page, searchDelayed, limit],
    queryFn: () => getLogs(page),
    cacheTime: 120 * (60 * 1000),
    keepPreviousData: true,
  });

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      setPage(0);
      setSearch(e.target.value);
    } else {
      setPage(0);
      setSearch(e.target.value);
    }
  };

  const handleClearSearch = () => {
    setPage(0);
    setSearch('');
  };

  const handlePerRowsChange = (e) => {
    setLimit(e.target.value);
  };

  return (
    <>
      <Breadcrumbs
        title="Usuários"
        pages={[{ page: localStorage.getItem('@eConform-ClientName') || 'Início', link: '/client/dashboard' }]}
      />

      <Content>
        <Paper
          title="Logs de Atividade"
          fluid
          loading={logsQuery.isLoading}
          fetching={logsQuery.isFetching || logsQuery.isRefetching}
        >
          <Stack
            direction={{ base: 'column', md: 'row' }}
            mb={logsQuery.data?.total == 0 && !search && !logsQuery.isLoading ? '0' : '8'}
            justify="space-between"
            spacing="4"
          >
            <ButtonSearch
              loading={logsQuery.isFetching}
              valueSearch={search}
              total={logsQuery.data?.total}
              handleSearch={handleSearch}
              handleClearSearch={handleClearSearch}
            />

            <Stack direction={{ base: 'column', md: 'row' }} spacing="4" align="center">
              <ShowingItemsLegend total={logsQuery.data?.total} limit={limit} page={page} />
              <Pagination
                page={page}
                handlePageChange={handlePageChange}
                pagesInRange={logsQuery.data?.page_in_range}
              />
            </Stack>
          </Stack>

          {logsQuery.data?.items && logsQuery.data?.total > 0 && !logsQuery.isLoading ? (
            <>
              <SimpleGrid columns={[1, 1, 1, 2, 2, 3, 4, 4, 5, 6]} spacing="4">
                {logsQuery.data?.items.map((log) => (
                  <VStack
                    key={log.id}
                    bgColor="grayBlue.100"
                    color="grayBlue.600"
                    fontSize="sm"
                    spacing="4"
                    p="4"
                    alignItems="left"
                    justify="space-between"
                    borderRadius="3px"
                    borderWidth="1px"
                    borderColor="grayBlue.100"
                    _hover={{ bgColor: 'grayBlue.200', textDecoration: 'none' }}
                  >
                    <HStack justify="space-between">
                      <Box>
                        <Heading size="xs" fontWeight="semibold">
                          {log.name}
                          <Text as="sup" color="grayBlue.500" fontWeight="light">
                            {log.user_id}
                          </Text>
                        </Heading>
                        <Text fontSize="sm" color="grayBlue.500" mb={2}>
                          {log.email}
                        </Text>
                        <Text>
                          {log.action_details}
                          {/* <chakra.span fontSize="3xs" color="grayBlue.500" ms="1">
                            ID{log.id}
                          </chakra.span> */}

                          {daysDistance(new Date(log.created_at), 30) && <Badge colorScheme="purple">Novo</Badge>}
                        </Text>
                      </Box>
                    </HStack>
                    <VStack spacing="2" align="stretch">
                      <Text fontSize="xs" color="grayBlue.500">
                        {date(log.created_at) || 'n/d'}
                      </Text>
                    </VStack>
                  </VStack>
                ))}
              </SimpleGrid>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                mt="8"
                spacing="4"
                align="center"
                justify="space-between"
              >
                <ItemsPerPage total={logsQuery.data?.total} limit={limit} handlePerRowsChange={handlePerRowsChange} />
                <HStack direction={{ base: 'column', md: 'row' }} spacing="4" align="center" justify="end">
                  <ShowingItemsLegend total={logsQuery.data?.total} limit={limit} page={page} />
                  <Pagination
                    page={page}
                    handlePageChange={handlePageChange}
                    pagesInRange={logsQuery.data?.pages_in_range}
                  />
                </HStack>
              </Stack>
            </>
          ) : logsQuery.data?.total == 0 && search && !logsQuery.isLoading ? (
            <Result
              title={`Nada encontrado para "${search}"`}
              description="Escolha outro termo para buscar."
              icon={AiOutlineFileSearch}
              options={
                <Button type="button" variant="ghost" colorScheme="grayBlue" onClick={handleClearSearch}>
                  Voltar à lista
                </Button>
              }
            />
          ) : logsQuery.data?.total == 0 && !logsQuery.isLoading ? (
            <Result title="Vazio" description="Adicione algum item para montar a lista." icon={BsInboxFill} />
          ) : null}
        </Paper>
      </Content>
    </>
  );
};
