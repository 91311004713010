import React, { useState, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ClientCompany } from 'services';
import { useNavigate } from 'react-router-dom';

import {
  chakra,
  Box,
  Button,
  ButtonGroup,
  Text,
  Heading,
  Icon,
  Flex,
  FormLabel,
  Image,
  useToast,
  VisuallyHidden,
  HStack,
  VStack,
  Spinner,
  SimpleGrid,
  GridItem,
  Grid,
  Divider,
} from '@chakra-ui/react';
import { date, formatCNPJ } from 'utils/format';
import LogoEConform from 'resources/logo/official/econform-logo-OriginalComFundoTransparente.png';
import BrasaoRFB from 'resources/images/brasaooficialcolorido.png';

export default React.forwardRef(({ companyId, document }, ref) => {
  let navigate = useNavigate();
  const toast = useToast();
  const [lastUpdateCompany, setLastUpdateCompany] = useState();

  const getCompany = async () => {
    try {
      const { data } = await ClientCompany.readItem(companyId);
      const response = await ClientCompany.lastUpdateDataItem(companyId);
      setLastUpdateCompany(response.data);
      return data;
    } catch (error) {
      toast({
        title: 'Não foi possível carregar as informações da empresa.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
      setTimeout(() => {
        navigate('/client/companies', { replace: true });
      }, 2000);
    }
  };

  const companyQuery = useQuery({
    queryKey: [`@eConform-getCompanyCnpjCard-${companyId}`, companyId],
    queryFn: () => getCompany(),
    keepPreviousData: true,
  });

  console.log('CnpjCard: ', companyQuery.data);

  const GridItemCustom = (props) => (
    <GridItem colSpan={props.colSpan} border="1px solid #CBD5E0" padding={2} textAlign="left" {...props}>
      <Box fontSize=".6rem" textTransform="uppercase">
        {props.title}
      </Box>
      <Box fontWeight="semibold" fontSize=".7rem">
        {props.children}
      </Box>
    </GridItem>
  );

  return (
    <Box ref={ref}>
      <HStack fontSize="xs">
        <Text>Emitido por: </Text>
        <img src={LogoEConform} width="70px" height="auto" alt="Logo eConform" />
      </HStack>
      <Box p="2" borderWidth={1} borderStyle="solid" borderColor="grayBlue.700">
        <Grid templateColumns="repeat(12, 1fr)" gap={2} color="grayBlue.700" fontSize="14px" borderRadius={4}>
          <GridItemCustom
            colSpan={12}
            height="100px"
            position="relative"
            justify="center"
            align="center"
            textAlign="center"
          >
            <Box position="absolute" top={15} left={30}>
              <img src={BrasaoRFB} width="70px" height="auto" alt="Brasão República Federativa do Brasil" />
            </Box>
            <Heading as="h1" size="md" mt={3}>
              REPÚBLICA FEDERATIVA DO BRASIL
            </Heading>
            <Heading as="h3" size="sm">
              CADASTRO NACIONAL DA PESSOA JURÍDICA
            </Heading>
          </GridItemCustom>

          <GridItemCustom colSpan={3} title="NÚMERO DE INSCRIÇÃO">
            {companyQuery?.data?.registration_code ? formatCNPJ(companyQuery?.data?.registration_code) : 'N/D'}
            <br />
            {companyQuery?.data?.type}
          </GridItemCustom>
          <GridItemCustom colSpan={6} title="">
            <Heading as="h3" size="sm" textAlign="center">
              COMPROVANTE DE INSCRIÇÃO E DE SITUAÇÃO CADASTRAL
            </Heading>
          </GridItemCustom>
          <GridItemCustom colSpan={3} title="DATA DE ABERTURA">
            {companyQuery?.data?.date_of_stablishment}
          </GridItemCustom>

          <GridItemCustom colSpan={12} title="NOME EMPRESARIAL">
            {companyQuery?.data?.registration_name == 'undefined' ||
            companyQuery?.data?.registration_name == 'null' ||
            companyQuery?.data?.registration_name == 'Não encontrado' ||
            !companyQuery?.data?.registration_name
              ? 'Não possui'
              : companyQuery?.data?.registration_name}
          </GridItemCustom>
          <GridItemCustom colSpan={12} title="TÍTULO DO ESTABELECIMENTO (NOME FANTASIA)">
            {companyQuery?.data?.registration_fantasy_name == 'undefined' ||
            companyQuery?.data?.registration_fantasy_name == 'null' ||
            companyQuery?.data?.registration_fantasy_name == 'Não encontrado' ||
            !companyQuery?.data?.registration_fantasy_name
              ? 'Não possui'
              : companyQuery?.data?.registration_fantasy_name}
          </GridItemCustom>
          <GridItemCustom colSpan={12} title="CÓDIGO E DESCRIÇÃO DA ATIVIDADE ECONÔMICA PRINCIPAL">
            {companyQuery?.data?.primary_activity?.length > 0
              ? companyQuery?.data?.primary_activity?.map((activity, i) => (
                  <Text key={i}>
                    {activity.code} - {activity.text}
                  </Text>
                ))
              : 'Não encontrado'}
          </GridItemCustom>
          <GridItemCustom colSpan={12} title="CÓDIGO E DESCRIÇÃO DAS ATIVIDADES ECONÔMICAS SECUNDÁRIAS">
            {companyQuery?.data?.secondary_activities?.length > 0
              ? companyQuery?.data?.secondary_activities?.map((activity, i) => (
                  <Text key={i}>
                    {activity.code} - {activity.text}
                  </Text>
                ))
              : 'Não encontrado'}
          </GridItemCustom>
          <GridItemCustom colSpan={12} title="CÓDIGO E DESCRIÇÃO DA NATUREZA JURÍDICA">
            {companyQuery?.data?.legal_nature}
          </GridItemCustom>

          <GridItemCustom colSpan={6} title="LOGRADOURO">
            {companyQuery?.data?.street ? companyQuery?.data?.street : 'Não cadastrado'}
          </GridItemCustom>
          <GridItemCustom colSpan={2} title="NÚMERO">
            {companyQuery?.data?.number && companyQuery?.data?.number}
          </GridItemCustom>
          <GridItemCustom colSpan={4} title="COMPLEMENTO">
            {companyQuery?.data?.complement && companyQuery?.data?.complement}
          </GridItemCustom>

          <GridItemCustom colSpan={2} title="CEP"></GridItemCustom>
          <GridItemCustom colSpan={4} title="BAIRRO/DISTRITO">
            {companyQuery?.data?.neighborhood && companyQuery?.data?.neighborhood}
          </GridItemCustom>
          <GridItemCustom colSpan={4} title="MUNICIPIO">
            {companyQuery?.data?.city_name ? companyQuery?.data?.city_name : 'Não cadastrado'}
          </GridItemCustom>
          <GridItemCustom colSpan={2} title="UF">
            {companyQuery?.data?.state_name ? companyQuery?.data?.state_name : ''}
          </GridItemCustom>

          <GridItemCustom colSpan={6} title="ENDEREÇO ELETRÔNICO">
            {companyQuery?.data?.contact_email ? companyQuery?.data?.contact_email : 'Email não cadastrado'}
          </GridItemCustom>
          <GridItemCustom colSpan={6} title="TELEFONE">
            {companyQuery?.data?.contact_phone ? companyQuery?.data?.contact_phone : 'Telefone não cadastrado'}
          </GridItemCustom>

          <GridItemCustom colSpan={12} title="ENTE FEDERATIVO RESPONSÁVEL (EFR)">
            {companyQuery?.data?.contact_name}
          </GridItemCustom>

          <GridItemCustom colSpan={8} title="SITUAÇÃO CADASTRAL">
            {companyQuery?.data?.registration_situation === 'INAPTA'
              ? 'SUSPENSA'
              : companyQuery?.data?.registration_situation || 'Não cadastrado'}
          </GridItemCustom>
          <GridItemCustom colSpan={4} title="DATA DA SITUAÇÃO CADASTRAL">
            {companyQuery?.data?.createdAt?.date ? <> desde {date(companyQuery?.data?.createdAt?.date)}</> : ''}
          </GridItemCustom>

          <GridItemCustom colSpan={12} title="MOTIVO DE SITUAÇÃO CADASTRAL"></GridItemCustom>

          <GridItemCustom colSpan={8} title="SITUAÇÃO ESPECIAL"></GridItemCustom>
          <GridItemCustom colSpan={4} title="DATA DA SITUAÇÃO ESPECIAL"></GridItemCustom>
        </Grid>
      </Box>
    </Box>
  );
});
