import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from 'styles/DefaultTheme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Routes from 'routes';
import './config/firebase';

import AppProvider from 'context';
import InjectAxiosInterceptors from 'services/InjectAxiosInterceptores';

const queryClient = new QueryClient();

function App() {
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AppProvider>
            <InjectAxiosInterceptors />
            <Routes />
          </AppProvider>
        </QueryClientProvider>
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
