import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: 'AIzaSyD8JjenwkfiW7DuApi35CQZQ_eFRMG_JDU',
    authDomain: 'econform-production.firebaseapp.com',
    databaseURL: 'https://econform-production.firebaseio.com',
    projectId: 'econform-production',
    storageBucket: 'econform-production.appspot.com',
    messagingSenderId: '811168221344',
    appId: '1:811168221344:web:a0c1f2a68a5cf417a4e1c5',
    measurementId: 'G-C76KPZGT7M',
  });
}

export default firebase;
