import React from 'react';

import Breadcrumbs from 'components/Breadcrumbs';
import { Box, Flex, Grid, Heading, HStack, Spacer, Text } from '@chakra-ui/react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import { Paper } from 'components/Content';

export default function ClientSupportPage() {
  return (
    <>
      <Breadcrumbs
        title="Suporte"
        pages={[{ page: localStorage.getItem('@eConform-ClientName') || 'Início', link: '/client/dashboard' }]}
      />

      <HStack align="justify">
        <Paper title="Perguntas frequentes" sizeTitle="md">
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Como alterar minha senha?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                No menu lateral esquerdo, clique na área que contém seu nome e email para abrir um submenu e em seguida
                clique em <em>Minha Conta</em>. Na área <em>Alterar Senha</em> você poderá registrar uma nova senha.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Preciso remover a licença vencida para anexar uma nova?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Não. Ela será removida automaticamente quando a nova for anexada e na página inicial, você receberá uma
                notificação avisando sobre a remoção do documento e a adição da novo.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Uma nova filial foi constituída. Como devo proceder?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Na tela inicial, clicar no ícone “empresas”, depois clicar em “‘+adicionar” , digitalizar o novo CNPJ e
                salvar.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Como faço para incluir a logo de uma filial?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Em uma filial que tenha a logo, clicar sobre a logo e “salvar como”. Depois ir para a filial que deseja
                incluir a logo, em “Dados”, selecionar a imagem, confirmar upload e salvar.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Estou tentando anexar um arquivo em PDF e a plataforma está criticando. O que fazer?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Retirar caracteres acentuados, espaços, numerais e pontos do nome do arquivo em questão.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Posso alterar o risco do meu cnpj?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>Sim, desde que faça uma revisão dos cnaes.</AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    O risco do cnpj, esta diferente do meu município, o que faço ?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Precisa verificar a classificação do cnaes nacional e a classificação dos cnaes no seu município.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Porque o risco do cnpj pode ser diferente?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Porque a lei federal, deu prerrogativa para os estados e municipios classificarem suas atividades, porem
                a classificação não varia excessivamente do federal.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Qual a importância de monitorar o risco do cnpj?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Porque ele tem impacto direto no licenciamento do seu empreendimento.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Posso ser multado por ter risco de cnpj diferentes?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>Não, porque a lei estabelece o risco maior do cnpj.</AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    As licenças são baseadas em que?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>São baseadas nos riscos.</AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Tenho que ter todas as licenças que a econform sugere?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>Não, desde que tenha base legal.</AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    As Licenças que não tenho como proceder?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Poderá contratar a econform ou um profissional especializado para assessoria.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Nas renovações das licenças posso ter ajuda da econoform?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>Sim</AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Como faço para obter ajuda nas renovações?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Envie um e-mail para vendas@econform.com.br e uma especialista irá ajudar.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Quem receberá os alertas dos vencimentos?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>Sempre os usuários</AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    A Inclusão e exclusão das licenças são alertadas?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>Sim.</AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Os alertas são baseados em quais funções?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>Inclusão, exclusão e vencimentos.</AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Receberei alerta de vencimentos diariamente.
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>Sim, desde que tenha licenças vencendo.</AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Após o vencimento receberei alertas?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>Receberá um alerta e ficará vencido no status das licenças.</AccordionPanel>
            </AccordionItem>

            {/* <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Como alterar minha senha?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.
              </AccordionPanel>
            </AccordionItem> */}
          </Accordion>
        </Paper>
        <Paper title="Politicas de segurança da internet e Termo de uso" sizeTitle="md">
          <Text textAlign="justify" fontSize="12px" overflowY="scroll" maxH="65vh">
            <p>
              <strong>Este termo de servi&ccedil;o cobre o uso e acesso de nossos servi&ccedil;os e website.</strong>
              <br />
              <br />
              Nossa Pol&iacute;tica de privacidade explica como coletamos e usamos suas informa&ccedil;&otilde;es,
              enquanto nossa Pol&iacute;tica de uso aceit&aacute;vel resume quais s&atilde;o suas responsabilidades ao
              usar nossos Servi&ccedil;os. Ao usar nossos Servi&ccedil;os, voc&ecirc; e empresas registradas, concordam
              em seguir estes Termos e nossas pol&iacute;ticas de Privacidade e Uso aceit&aacute;vel.
              <br />
              <br />
              <strong>Documentos e permiss&otilde;es</strong>
              <br />
              <br />
              Quando usa nossos Servi&ccedil;os, voc&ecirc; nos fornece informa&ccedil;&otilde;es, como seus documentos
              e conte&uacute;dos, contratos, contatos, dentre outros. Seus documentos s&atilde;o seus. Estes Termos
              n&atilde;o concedem nenhum direito sobre Seus arquivos, exceto os direitos limitados que nos permitem
              oferecer os Servi&ccedil;os.
              <br />
              Precisamos de sua permiss&atilde;o para hospedar seus arquivos, fazer backups e compartilh&aacute;-los
              quando voc&ecirc; solicitar, entre outras a&ccedil;&otilde;es. Nossos Servi&ccedil;os tamb&eacute;m
              poder&atilde;o fornecem recursos como miniaturas de imagens, pr&eacute;-visualiza&ccedil;&atilde;o de
              documentos, coment&aacute;rios, classifica&ccedil;&atilde;o facilitada, compartilhamento e pesquisa. Esses
              e outros recursos podem exigir que nossos sistemas acessem, armazenem e escaneiem Seus arquivos.
              Voc&ecirc; nos d&aacute; permiss&atilde;o para fazer essas a&ccedil;&otilde;es, e essa permiss&atilde;o se
              estende a nossos afiliados e terceiros de confian&ccedil;a com quem trabalhamos.
              <br />
              <br />
              <strong>Compartilhamento de seus arquivos</strong>
              <br />
              <br />
              Nossos Servi&ccedil;os permitem que voc&ecirc; compartilhe Seus arquivos com outros usu&aacute;rios,
              ent&atilde;o avalie com cuidado o que vai compartilhar.
              <br />
              <br />
              <strong>Suas responsabilidades</strong>
              <br />
              <br />
              Voc&ecirc; &eacute; respons&aacute;vel pela sua conduta e por seus documentos. Voc&ecirc; deve estar de
              acordo com nossa Pol&iacute;tica de uso aceit&aacute;vel. O conte&uacute;do dos documentos em nossos
              Servi&ccedil;os podem estar protegidos por direitos de propriedade intelectual de outras pessoas. Pedimos
              que s&oacute; copie, envie, baixe ou compartilhe conte&uacute;do se tiver o direito de faz&ecirc;-lo.
              Podemos rever sua conduta e conte&uacute;dos para verificar a conformidade com os Termos e com a nossa
              Pol&iacute;tica de uso aceit&aacute;vel. Contudo, n&atilde;o somos obrigados a faz&ecirc;-lo. N&atilde;o
              somos respons&aacute;veis pelo conte&uacute;do que as pessoas publicam e compartilham por meio dos
              Servi&ccedil;os.
              <br />
              <br />
              Ajude-nos a mant&ecirc;-lo deixando seus arquivos protegidos. Proteja sua senha de acesso aos
              Servi&ccedil;os e mantenha suas informa&ccedil;&otilde;es de conta atualizadas. N&atilde;o compartilhe
              suas credenciais de conta nem forne&ccedil;a acesso &agrave; sua conta para outras pessoas. Voc&ecirc;
              pode usar nossos Servi&ccedil;os apenas conforme permitido pela legisla&ccedil;&atilde;o aplic&aacute;vel.
              Finalmente, para usar nossos servi&ccedil;os, voc&ecirc; deve ter pelo menos 18 anos e morar no Brasil. Se
              voc&ecirc; n&atilde;o atender a esses requisitos de idade e resid&ecirc;ncia, n&atilde;o poder&aacute;
              usar os Servi&ccedil;os.
              <br />
              <br />
              <strong>Software</strong>
              <br />
              <br />
              Enquanto voc&ecirc; cumprir com estes Termos, forneceremos uma licen&ccedil;a limitada, n&atilde;o
              exclusiva, intransfer&iacute;vel, revog&aacute;vel para usar o Software e Servi&ccedil;os. A menos que as
              seguintes restri&ccedil;&otilde;es sejam proibidas por lei, voc&ecirc; concorda em n&atilde;o fazer
              engenharia reversa dos Servi&ccedil;os, tentar faz&ecirc;-lo ou ajudar algu&eacute;m a faz&ecirc;-lo.
              <br />
              <br />
              <strong>Servi&ccedil;os Beta</strong>
              <br />
              <br />
              &Agrave;s vezes lan&ccedil;amos produtos e recursos que ainda est&atilde;o sendo testados e avaliados.
              Estes Servi&ccedil;os s&atilde;o identificados como beta, pr&eacute;via, acesso antecipado ou
              avalia&ccedil;&atilde;o (ou outras palavras ou frases de significado semelhante) e podem n&atilde;o ter o
              mesmo n&iacute;vel de confiabilidade que os outros servi&ccedil;os, ent&atilde;o &eacute; importante que
              voc&ecirc; leve isso em considera&ccedil;&atilde;o.
              <br />
              <br />
              <strong>Nossos arquivos</strong>
              <br />
              <br />
              Os Servi&ccedil;os s&atilde;o protegidos por direitos autorais (copyright), marcas registradas. Estes
              Termos n&atilde;o lhe concedem qualquer direito, titularidade ou participa&ccedil;&atilde;o nos
              Servi&ccedil;os, conte&uacute;do de outras pessoas, nas marcas, logos e outros recursos
              caracter&iacute;sticos da marca E-conform. Agradecemos seus coment&aacute;rios e sugest&otilde;es, mas
              note que poderemos utilizar coment&aacute;rios ou sugest&otilde;es sem qualquer obriga&ccedil;&atilde;o
              para com voc&ecirc;.
              <br />
              <br />
              <strong>Direito autoral (copyright)</strong>
              <br />
              <br />
              Respeitamos a propriedade de terceiros e pedimos que voc&ecirc; tamb&eacute;m o fa&ccedil;a. Reservamo-nos
              o direito de excluir ou desativar conte&uacute;do supostamente infrator e encerrar contas de infratores
              reincidentes ou n&atilde;o.
              <br />
              <br />
              <strong>Faturamento</strong>
              <br />
              <br />
              Voc&ecirc; pode aumentar os limites de sua conta e adicionar novos recursos alterando de plano. Vamos
              fatur&aacute;-lo automaticamente a partir da data em que voc&ecirc; converter sua conta para um novo plano
              e em cada renova&ccedil;&atilde;o peri&oacute;dica at&eacute; o cancelamento. Voc&ecirc; &eacute;
              respons&aacute;vel pelo pagamento de todos os impostos, e vamos incluir a cobran&ccedil;a de impostos
              quando formos obrigados a faz&ecirc;-lo.
              <br />
              <br />
              <strong>Sem reembolsos</strong>
              <br />
              <br />
              Voc&ecirc; pode cancelar sua conta do E-conform a qualquer momento. Reembolsos somente s&atilde;o emitidos
              se exigidos por lei.
              <br />
              <br />
              <strong>Migrar para plano inferior</strong>
              <br />
              <br />
              Caso opte por migrar para um plano inferir, isto &eacute;, que inclua menos servi&ccedil;os e recursos,
              n&oacute;s nos reservamos o direito de reduzir seu armazenamento para n&iacute;veis compat&iacute;veis com
              o plano contratado.
              <br />
              <br />
              <strong>Tarifas</strong>
              <br />
              <br />
              Podemos alterar as tarifas em vigor, mas voc&ecirc; ser&aacute; notificado com anteced&ecirc;ncia a
              respeito dessas mudan&ccedil;as por meio de uma mensagem enviada para o e-mail associado &agrave; sua
              conta.
              <br />
              <br />
              <strong>Rescis&atilde;o</strong>
              <br />
              <br />
              Voc&ecirc; pode parar de usar nossos Servi&ccedil;os a qualquer momento. N&oacute;s nos reservamos o
              direito de suspender ou encerrar seu acesso aos Servi&ccedil;os, mediante notifica&ccedil;&atilde;o, caso:
              <br />
              <br />
              a - voc&ecirc; esteja violando estes Termos,
              <br />
              b - voc&ecirc; esteja usando os Servi&ccedil;os de maneira que possam causar risco real de perigo ou
              preju&iacute;zo a n&oacute;s ou a outros usu&aacute;rios.
              <br />
              <br />
              Enviaremos uma notifica&ccedil;&atilde;o pr&eacute;via com tempo razo&aacute;vel ao endere&ccedil;o de
              e-mail associado &agrave; sua conta para que voc&ecirc; possa corrigir a atividade que nos levou a
              contat&aacute;-lo, e para lhe dar oportunidade de exportar seus documentos de nossos servi&ccedil;os. Se,
              ap&oacute;s essa notifica&ccedil;&atilde;o, voc&ecirc; n&atilde;o tomar as provid&ecirc;ncias solicitadas,
              rescindiremos ou suspenderemos seu acesso aos Servi&ccedil;os.
              <br />
              <br />
              N&atilde;o forneceremos notifica&ccedil;&atilde;o antes da rescis&atilde;o quando:
              <br />
              <br />
              a - voc&ecirc; estiver cometendo uma viola&ccedil;&atilde;o material destes Termos,
              <br />
              b - faz&ecirc;-lo causaria responsabilidade legal ou comprometeria nossa capacidade de fornecer os
              Servi&ccedil;os a outros usu&aacute;rios, ou
              <br />
              c - formos proibidos por lei de faz&ecirc;-lo.
              <br />
              <br />
              <strong>Interrup&ccedil;&atilde;o dos Servi&ccedil;os</strong>
              <br />
              <br />
              Podemos decidir pela interrup&ccedil;&atilde;o dos Servi&ccedil;os em resposta a circunst&acirc;ncias
              n&atilde;o previstas e que estejam fora do controle do E-conform ou para atender a requisitos legais. Caso
              o fa&ccedil;amos, enviaremos notifica&ccedil;&atilde;o pr&eacute;via em tempo razo&aacute;vel para que
              voc&ecirc; possa exportar seus documentos de nossos sistemas. Caso descontinuemos os Servi&ccedil;os dessa
              maneira antes de qualquer prazo m&iacute;nimo ou fixo pelo qual voc&ecirc; tenha pago antecipadamente,
              vamos reembols&aacute;-lo proporcionalmente pelas taxas dos Servi&ccedil;os pagos, mas n&atilde;o
              recebidos.
              <br />
              <br />
              <strong>Servi&ccedil;os &quot;TAIS COMO EST&Atilde;O&quot;</strong>
              <br />
              <br />
              N&oacute;s nos esfor&ccedil;amos para oferecer excelentes Servi&ccedil;os, mas h&aacute; certas garantias
              que n&atilde;o podemos oferecer. AT&Eacute; ONDE PERMITIDO POR LEI, O E-Conform E SEUS AFILIADOS,
              FORNECEDORES E DISTRIBUIDORES N&Atilde;O OFERECEM NENHUMA GARANTIA, EXPRESSA OU IMPL&Iacute;CITA, SOBRE OS
              SERVI&Ccedil;OS. OS SERVI&Ccedil;OS S&Atilde;O FORNECIDOS &quot;TAIS COMO EST&Atilde;O&quot;.
              TAMB&Eacute;M REJEITAMOS QUALQUER GARANTIA DE COMERCIALIZA&Ccedil;&Atilde;O, ADEQUA&Ccedil;&Atilde;O A UM
              DETERMINADO FIM E N&Atilde;O VIOLA&Ccedil;&Atilde;O. Limita&ccedil;&atilde;o de responsabilidade
              N&Atilde;O EXCLU&Iacute;MOS OU LIMITAMOS NOSSA RESPONSABILIDADE PARA COM VOC&Ecirc; QUANDO FOR ILEGAL
              FAZ&Ecirc;-LO. ISSO INCLUI QUALQUER RESPONSABILIDADE RESULTANTE DE FRAUDE OU DECLARA&Ccedil;&Otilde;ES
              FRAUDULENTAS POR PARTE DO E- Conform OU SEUS AFILIADOS AO FORNECER OS SERVI&Ccedil;OS. EM PA&Iacute;SES
              ONDE OS SEGUINTES TIPOS DE EXCLUS&Atilde;O N&Atilde;O S&Atilde;O PERMITIDOS, SOMOS RESPONS&Aacute;VEIS
              APENAS POR PERDAS E DANOS RAZOAVELMENTE PREVIS&Iacute;VEIS RESULTANTES DE NOSSA FALHA AO MANIFESTAR O
              CUIDADO E CAPACIDADE ADEQUADAS OU SE VIOLARMOS NOSSO CONTRATO COM VOC&Ecirc;. ESTE PAR&Aacute;GRAFO
              N&Atilde;O AFETA DIREITOS DO CONSUMIDOR QUE N&Atilde;O PODEM SER RENUNCIADOS OU LIMITADOS POR UM CONTRATO
              OU ACORDO.
              <br />
              <br />
              EM PA&Iacute;SES EM QUE EXCLUS&Otilde;ES OU LIMITA&Ccedil;&Otilde;ES DE RESPONSABILIDADE S&Atilde;O
              PERMITIDAS, O E-Conform, SEUS AFILIADOS, FORNECEDORES OU DISTRIBUIDORES N&Atilde;O SER&Atilde;O
              RESPONSABILIZADOS POR:
              <br />
              <br />
              i. QUAISQUER DANOS INDIRETOS, ESPECIAIS, INCIDENTAIS, PUNITIVOS, EXEMPLARES OU CONSEQUENTES, OU
              <br />
              ii. POR QUALQUER PERDA DE USO, DADOS, NEG&Oacute;CIOS OU LUCROS, INDEPENDENTEMENTE DE TEORIA
              JUR&Iacute;DICA. ESTAS EXCLUS&Otilde;ES OU LIMITA&Ccedil;&Otilde;ES S&Atilde;O APLICADAS QUER O E-Conform
              OU SEUS AFILIADOS TENHAM OU N&Atilde;O SIDO AVISADOS A RESPEITO DA POSSIBILIDADE DE TAIS DANOS.
              <br />
              <br />
              O E-Conform, SEUS AFILIADOS, FORNECEDORES OU DISTRIBUIDORES N&Atilde;O SER&Atilde;O RESPONSABILIZADOS POR
              QUAISQUER LUCROS CESSANTES, PERDA DE NEG&Oacute;CIOS, INTERRUP&Ccedil;&Atilde;O DE NEG&Oacute;CIOS OU
              PERDA DE OPORTUNIDADE DE NEG&Oacute;CIOS. O E-Conform E SEUS AFILIADOS N&Atilde;O S&Atilde;O
              RESPONS&Aacute;VEIS PELA CONDUTA DE QUALQUER USU&Aacute;RIO DOS SERVI&Ccedil;OS, SEJA ON-LINE OU OFF-LINE.
              PARA AL&Eacute;M DOS TIPOS DE RESPONSABILIDADE QUE POR LEI N&Atilde;O PODEMOS LIMITAR (COMO DESCRITO NESTA
              SE&Ccedil;&Atilde;O), LIMITAMOS NOSSA RESPONSABILIDADE PARA COM VOC&Ecirc; A UM M&Aacute;XIMO DE R$100 OU
              A 100% DE QUALQUER QUANTIA QUE VOC&Ecirc; TENHA PAGO EM SEU PLANO DE SERVI&Ccedil;O ATUAL DO E-Conform.
              <br />
              <br />
              <strong>Resolvendo disputas</strong>
              <br />
              <br />
              Vamos tentar resolver as coisas primeiro. Queremos atender &agrave;s suas preocupa&ccedil;&otilde;es, sem
              a necessidade de um processo judicial formal. Antes de entrar com uma reclama&ccedil;&atilde;o contra o
              E-Conform, voc&ecirc; concorda em tentar resolver a disputa informalmente, entrando em contato pelo e-mail
              juridico@econform.com.br. Vamos tentar resolver a disputa informalmente entrando em contato com voc&ecirc;
              por e-mail. Caso uma disputa n&atilde;o seja resolvida no prazo de 15 dias ap&oacute;s o envio, voc&ecirc;
              ou o E-Conform podem dar entrada em um processo formal. Foro judicial para disputas
              <br />
              Voc&ecirc; e o E-Conform concordam que qualquer procedimento judicial para resolver
              reclama&ccedil;&otilde;es referentes a estes Termos ou aos Servi&ccedil;os ser&aacute; apresentado aos
              tribunais ou juizado especial do forro de Belo Horizonte, e estar&aacute; sujeito &agrave;s
              disposi&ccedil;&otilde;es obrigat&oacute;rias sobre arbitragem. O foro e a compet&ecirc;ncia jurisdicional
              devem ser objeto de acordo m&uacute;tuo entre voc&ecirc; e o E-Conform.
              <br />
              <br />
              <strong>PROIBI&Ccedil;&Atilde;O DE A&Ccedil;&Otilde;ES COLETIVAS</strong>
              <br />
              <br />
              Voc&ecirc; apenas pode resolver conosco disputas em n&iacute;vel individual, e n&atilde;o pode fazer uma
              reclama&ccedil;&atilde;o como requerente ou membro de algum grupo, em uma a&ccedil;&atilde;o coletiva,
              consolidada ou por substitui&ccedil;&atilde;o derivada. Arbitragens coletivas, a&ccedil;&otilde;es
              coletivas, a&ccedil;&otilde;es de interesse p&uacute;blico e consolida&ccedil;&atilde;o com outras
              arbitragens n&atilde;o s&atilde;o permitidas. Se este par&aacute;grafo espec&iacute;fico n&atilde;o for
              aplic&aacute;vel, toda a se&ccedil;&atilde;o &quot;Disposi&ccedil;&otilde;es obrigat&oacute;rias sobre a
              arbitra&ccedil;&atilde;o&quot; ser&aacute; considerada inv&aacute;lida.
              <br />
              <br />
              <strong>Lei aplic&aacute;vel</strong>
              <br />
              <br />
              Estes Termos s&atilde;o regidos pelas leis vigentes no Brasil. Cl&aacute;usula de integralidade de
              entendimentos Estes Termos constituem o acordo integral entre voc&ecirc; e o E-Conform no que diz respeito
              ao assunto destes Termos, e anulam e substituem quaisquer outros acordos anteriores ou
              contempor&acirc;neos, ou termos e condi&ccedil;&otilde;es aplic&aacute;veis ​​ao assunto destes Termos.
              Estes Termos n&atilde;o criam quaisquer direitos de terceiros benefici&aacute;rios.
              <br />
              <br />
              <strong>Ren&uacute;ncia, independ&ecirc;ncia das cl&aacute;usulas e cess&atilde;o</strong>
              <br />
              <br />
              O fato de o E-Conform n&atilde;o exigir o cumprimento de uma disposi&ccedil;&atilde;o ora acordada
              n&atilde;o configura ren&uacute;ncia a seu direito de faz&ecirc;-lo mais tarde. Caso se perceba que uma
              disposi&ccedil;&atilde;o &eacute; nula, as disposi&ccedil;&otilde;es restantes dos Termos
              permanecer&atilde;o em pleno vigor e um termo v&aacute;lido substituir&aacute; o termo nulo, refletindo
              nossa inten&ccedil;&atilde;o, tanto quanto poss&iacute;vel. Voc&ecirc; n&atilde;o deve ceder nenhum dos
              seus direitos ao abrigo destes Termos e qualquer tentativa nesse sentido ser&aacute; nula. O E-Conform
              pode ceder seus direitos a qualquer de sues afiliados ou subsidi&aacute;rias ou a qualquer parte que venha
              a suced&ecirc;-lo na participa&ccedil;&atilde;o de qualquer empresa associada com os Servi&ccedil;os.
              <br />
              <br />
              <strong>Modifica&ccedil;&otilde;es</strong>
              <br />
              <br />
              Estes Termos podem ser revisados de tempos em tempos para melhor refletir:
              <br />
              <br />
              a - mudan&ccedil;as na legisla&ccedil;&atilde;o,
              <br />
              b - novos requisitos regulamentares, ou
              <br />
              c - melhorias ou aprimoramentos feitos em nossos Servi&ccedil;os.
              <br />
              <br />
              Caso uma atualiza&ccedil;&atilde;o afete seu uso dos Servi&ccedil;os ou seus direitos legais como
              usu&aacute;rio de nossos Servi&ccedil;os, vamos notific&aacute;-lo com anteced&ecirc;ncia da data de
              efetiva&ccedil;&atilde;o da atualiza&ccedil;&atilde;o entrando em contato pelo endere&ccedil;o de e-mail
              associado &agrave; sua conta ou por uma notifica&ccedil;&atilde;o direta pelo produto. Os termos
              atualizados entrar&atilde;o em vigor com o prazo m&iacute;nimo de 30 dias ap&oacute;s a
              notifica&ccedil;&atilde;o.
              <br />
              <br />
              Caso n&atilde;o concorde com as atualiza&ccedil;&otilde;es que fizermos, cancele sua conta antes da
              efetiva&ccedil;&atilde;o. Quando aplic&aacute;vel, vamos lhe oferecer um reembolso proporcional com base
              nas quantias pagas anteriormente pelos Servi&ccedil;os e na data de cancelamento de sua conta. Caso
              voc&ecirc; continue a acessar ou usar os Servi&ccedil;os ap&oacute;s as atualiza&ccedil;&otilde;es
              entrarem em vigor, fica entendido que voc&ecirc; concorda em seguir os Termos revisados.
            </p>
          </Text>
        </Paper>
      </HStack>
    </>
  );
}
