import React, { useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import { ClientMyAccount } from 'services';
import jwt from 'jsonwebtoken';
import { useAuth } from 'context/auth';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
  useToast,
  Button,
  ButtonGroup,
  VStack,
  VisuallyHiddenInput,
  Box,
  Flex,
  SimpleGrid,
  Badge,
} from '@chakra-ui/react';

import Breadcrumbs from 'components/Breadcrumbs';
import { Content, Paper } from 'components/Content';
import { Loading } from 'components/Loading';
import { Input } from 'components/Forms/Input';
import { Radio } from 'components/Forms/Radio';
import { Checkbox } from 'components/Forms/Checkbox';

import Delete from './delete';

const schema = Yup.object({
  name: Yup.string().required('Nome obrigatório').min(2, 'Mínimo de 2 caracteres').max(255, 'Máximo de 255 caracteres'),
  email: Yup.string().required('Email obrigatório').email('Email inválido'),
  password: Yup.string(),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais.'),
});

export default () => {
  const toast = useToast();
  const { auth, setAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [deleteItem, setDeleteItem] = useState(0);
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({ resolver: yupResolver(schema) });

  const handleConstruct = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await ClientMyAccount.readItem();
      reset({ ...data, systemRole: data?.access_role, email: data?.username, password: '' });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: 'Não foi possível carregar o usuário.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  }, [auth, reset]);

  const handleOnSubmit = useCallback(
    async (data) => {
      data.password === ''
        ? (data = {
            name: data?.name,
            email: data?.email,
            systemRole: data?.systemRole,
            enabled: data?.enabled,
          })
        : data;

      try {
        const response = await ClientMyAccount.updateItem(data);

        localStorage.setItem('@eConform.token', response?.data?.access_token);
        api.defaults.headers.Authorization = response?.data?.access_token;
        let decodedToken = jwt.verify(response?.data?.access_token, 'secretKey');
        await setTimeout(
          () =>
            setAuth({
              isAuth: true,
              id: decodedToken.id,
              uid: decodedToken.uid,
              current_client_id: decodedToken.current_client_id,
              token: data.access_token,
              name: decodedToken.name,
              email: decodedToken.username,
              access_role: decodedToken.access_role,
              permission_role: decodedToken.permission_role,
            }),
          1000
        );
        toast({
          title: 'Dados alterados com sucesso!',
          status: 'success',
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Não foi possível alterar o usuário.',
          description: error.response.data.message
            ? error.response.data.message
            : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [auth, reset]
  );

  useEffect(() => {
    if (auth.id > 0) {
      handleConstruct();
    }
  }, [auth, handleConstruct]);

  return (
    <>
      <Breadcrumbs
        title="Minha conta"
        pages={[{ page: localStorage.getItem('@eConform-ClientName') || 'Início', link: '/client/dashboard' }]}
      />
      <Content>
        <Paper title="Meu perfil" loading={isSubmitting || loading} fluid>
          {!loading && !isSubmitting && (
            <VStack as="form" onSubmit={handleSubmit(handleOnSubmit)} spacing="8" align="stretch">
              <SimpleGrid minChildWidth={{ base: '320px', '6xl': '380px' }} spacing="8">
                <Input name="name" label="Nome" errors={errors} register={register} />
                <Input name="email" label="E-mail" errors={errors} register={register} />
                <VisuallyHiddenInput name="password" register={register} />
                {getValues('systemRole') === 'ADMIN' || getValues('systemRole') === 'USER' ? (
                  <Radio
                    name="systemRole"
                    label="Tipo de usuário"
                    minChildWidth="120px"
                    getValues={getValues}
                    defaultValue="ADMIN"
                    options={[
                      {
                        label: (
                          <Badge colorScheme="green" textTransform="capitalize" fontSize="14">
                            Administrador
                          </Badge>
                        ),
                        value: 'ADMIN',
                      },
                      {
                        label: (
                          <Badge colorScheme="blue" textTransform="capitalize" fontSize="14">
                            Usuário
                          </Badge>
                        ),
                        value: 'USER',
                      },
                    ]}
                    isDisabled={auth.access_role !== 'ECONFORM' && auth.access_role !== 'ADMIN'}
                    register={register}
                    errors={errors}
                  />
                ) : (
                  <Radio
                    name="systemRole"
                    label="Tipo de usuário"
                    minChildWidth="120px"
                    getValues={getValues}
                    defaultValue="ADMIN"
                    options={[
                      {
                        label: (
                          <Badge colorScheme="green" textTransform="capitalize" fontSize="14">
                            eConform
                          </Badge>
                        ),
                        value: 'ECONFORM',
                      },
                      {
                        label: (
                          <Badge colorScheme="yellow" textTransform="capitalize" fontSize="14">
                            Editor
                          </Badge>
                        ),
                        value: 'EDITOR',
                      },
                      /* {
                        label: (
                          <Badge colorScheme="purple" textTransform="capitalize" fontSize="14">
                            Parceiro
                          </Badge>
                        ),
                        value: 'PARTNER',
                      }, */
                      {
                        label: (
                          <Badge colorScheme="blue" textTransform="capitalize" fontSize="14">
                            Affinity
                          </Badge>
                        ),
                        value: 'AFFINITY',
                      },
                    ]}
                    isDisabled={auth.access_role !== 'ECONFORM' && auth.access_role !== 'ADMIN'}
                    register={register}
                    errors={errors}
                  />
                )}
                <VisuallyHiddenInput name="enabled" register={register} />
              </SimpleGrid>
              <Flex justify="end">
                <ButtonGroup spacing="4">
                  <Button type="submit" isLoading={isSubmitting}>
                    Salvar
                  </Button>
                  <Button colorScheme="red" onClick={() => setDeleteItem(auth.id)}>
                    Excluir conta
                  </Button>
                </ButtonGroup>
              </Flex>
            </VStack>
          )}
        </Paper>
        <Paper title="Alterar senha" fluid>
          {!loading && !isSubmitting && (
            <VStack as="form" id="formRef2" onSubmit={handleSubmit(handleOnSubmit)} spacing="8" align="stretch">
              <SimpleGrid minChildWidth={{ base: '320px', '6xl': '380px' }} spacing="8">
                <Input name="password" type="password" label="Senha" errors={errors} register={register} />
                <Input
                  name="passwordConfirmation"
                  type="password"
                  label="Confirmar senha"
                  errors={errors}
                  register={register}
                />
                <VisuallyHiddenInput name="name" register={register} />
                <VisuallyHiddenInput name="email" register={register} />
                <VisuallyHiddenInput name="systemRole" register={register} />
                <VisuallyHiddenInput name="enabled" register={register} />
              </SimpleGrid>
              <Flex justify="end">
                <ButtonGroup spacing="4" justify="end">
                  <Button type="submit" isLoading={isSubmitting}>
                    Alterar senha
                  </Button>
                </ButtonGroup>
              </Flex>
            </VStack>
          )}
        </Paper>
      </Content>
      <Delete
        id={deleteItem}
        isOpen={!!deleteItem}
        onClose={() => {
          setDeleteItem(0);
        }}
      />
    </>
  );
};
