import React, { useState, useMemo } from 'react';
import nprogress from 'nprogress';
import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { ClientCompany } from 'services';

import DefaultTable from 'components/Tables/DefaultTable';
import { Content, Paper } from 'components/Content';
import Result from 'components/Result';

import { useToast, Button, Badge, Text, HStack, IconButton, Icon } from '@chakra-ui/react';

import { RiDeleteBin2Fill } from 'react-icons/ri';
import { ImBlocked } from 'react-icons/im';

import Create from './create';
import Delete from './delete';

import { useDelayedValue } from 'hooks/useDelayedValue';
import { date, daysDistance } from 'utils/format';
import { useCan } from 'hooks/useCan';

export default function ClientCompaniesSettingsCNPJsPage() {
  let { id: companyId } = useParams();
  const [searchParams] = useSearchParams();
  const [cnaeLevel, setCnaeLevel] = useState(searchParams.get('cnae_level'));
  const toast = useToast();
  const createCNPJCan = useCan({ permission_role: ['CREATE_CNPJ'] });
  const deleteCNPJCan = useCan({ permission_role: ['DELETE_CNPJ'] });
  const getCNPJCan = useCan({ permission_role: ['GET_CNPJ'] });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(20);
  const [sort, setSort] = useState('et.id');
  const [direction, setDirection] = useState('desc');
  const [newItem, setNewItem] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [registrationCodeItem, setRegistrationCodeItem] = useState(false);
  const searchDelayed = useDelayedValue(search, 1000);

  const columns = useMemo(
    () => ['ID', 'Tipo', 'Empresa/CNPJ', 'Município/UF', 'Situação', 'Registro', 'Data de registro'],
    []
  );

  const getCNPJs = async (page = 0) => {
    try {
      nprogress.start();
      const { data } = await ClientCompany.cnpjIndex(
        companyId,
        `&search=${searchDelayed}&page=${page}&limit=${limit}&sort=${sort}&direction=${direction}`
      );
      return data;
      nprogress.done();
    } catch (error) {
      nprogress.done();
      toast({
        title: 'Não foi possível carregar os CNPJs.',
        description: error.response.data.message
          ? error.response.data.message
          : 'Motivo desconhecido. Entre em contato conosco para relatar este impedimento.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const cnpjsQuery = useQuery({
    queryKey: [`@eConform-getCNPJs-${companyId}`, page, searchDelayed, limit],
    queryFn: () => getCNPJs(page),
    keepPreviousData: true,
  });

  const dataItems = cnpjsQuery.data?.items?.map((item) => ({
    id: parseInt(item.id),
    type:
      item.type === 'MATRIZ' ? <Badge colorScheme="purple">Matriz</Badge> : <Badge colorScheme="blue">Filial</Badge>,
    registrationName: (
      <>
        <Text
          fontWeight="semibold"
          color={item.enabled ? 'grayBlue.500' : 'red.400'}
          textTransform="capitalize"
          casing="capitalize"
        >
          {item.registrationName}
          {!item.enabled && <Badge colorScheme="red">Inativo</Badge>}

          {daysDistance(new Date(item.registrationSituationDate?.date), 30) && <Badge colorScheme="purple">Novo</Badge>}
        </Text>
        <Text>{item.registrationCode.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}</Text>
      </>
    ),
    systemCityNameStateAlias: (
      <Text>
        {item.systemCityName} / {item.systemStateAlias}
      </Text>
    ),
    registrationSituation: item.registrationSituation,
    registrationStatus: item.registrationStatus,
    registrationSituationDate: item.registrationSituationDate.date ? date(item.registrationSituationDate.date) : 'n/a',
  }));

  return (
    <>
      <Content>
        <Paper
          title="CNPJs"
          fluid
          loading={cnpjsQuery.isLoading}
          fetching={cnpjsQuery.isFetching || cnpjsQuery.isRefetching}
          sizeTitle="md"
          options={
            <Button type="button" onClick={() => setNewItem(true)} isDisabled={!createCNPJCan}>
              Adicionar
            </Button>
          }
        >
          {getCNPJCan ? (
            <DefaultTable
              columns={columns}
              data={dataItems}
              page={page}
              total={cnpjsQuery.data?.total}
              limit={limit}
              loading={cnpjsQuery.isLoading}
              valueSearch={search}
              handleSearch={(e) => {
                if (e.key === 'Enter') {
                  setPage(0);
                  setSearch(e.target.value);
                } else {
                  setPage(0);
                  setSearch(e.target.value);
                }
              }}
              handleClearSearch={() => {
                setPage(0);
                setSearch('');
              }}
              fetchData={() => getCNPJs(0)}
              handlePerRowsChange={(event) => {
                setLimit(event.target.value);
              }}
              handlePageChange={(item) => {
                setPage(item);
              }}
              pagesInRange={cnpjsQuery.data?.page_in_range}
            />
          ) : (
            <Result
              title="Acesso restrito"
              description="Você não tem permissão para visualizar esta listagem."
              icon={ImBlocked}
            />
          )}
        </Paper>
      </Content>

      {createCNPJCan ? (
        <Create
          companyId={companyId}
          isOpen={newItem}
          onClose={() => {
            setNewItem(false);
            cnpjsQuery.refetch();
          }}
        />
      ) : null}

      {deleteCNPJCan ? (
        <Delete
          id={deleteItem}
          companyId={companyId}
          registrationCode={String(registrationCodeItem)}
          isOpen={!!deleteItem}
          onClose={() => {
            setDeleteItem(0);
            cnpjsQuery.refetch();
          }}
        />
      ) : null}
    </>
  );
}
